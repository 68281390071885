/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/7/2022
 * @Example
 */

import React, { useEffect, useState, useRef, useMemo } from "react";
import {useDispatch, useSelector} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W29F2300Actions from "../../../../redux/W2X/W29F2300/W29F2300_actions";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TabContainer,
    TabHeader,
    TabItem, Checkbox, Button, DateRangePicker, TreeView, Dropdown, Col, Row,
}                         from "diginet-core-ui/components";
import Config             from "../../../../config";
import ActionToolbar      from "../../../common/toolbar/action-toolbar";
import Filter             from "../../../filter/filter";
import { makeStyles }     from "diginet-core-ui/theme";
import { Column }         from "devextreme-react/data-grid";
import GridContainer      from "../../../grid-container/grid-container";
import PopoverShowHideCol from '../../../common/popover-show-hide-col';
import Status             from "../../../common/status/status";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
    row: {
        height: 'calc(100% - 5px)',
    },
    leftColScroll: {
        maxHeight: "calc(100vh - 100px)",
        overflowY: "auto"
    },
    leftCol: {
        padding: theme.spacing([4, 4, 0, 2]),
        [theme.breakpoints.down("xl")]: {
            padding: theme.spacing([4, 2, 0, 0]),
        }
    },
    col: {
        height: "100%",
    },
    borderLeft : {
        borderLeft: `1px solid ${theme.colors?.line?.system}`,
    }
}));

const dataColumnGridExtra = () =>  [
    {
        caption: Config.lang('Nhan_vien'),
        width: 250,
        dataField :'EmployeeName',
    },
    {
        caption: Config.lang('Trang_thai'),
        dataField :'StatusID',
        isRenderStatus: true,
    },
    {
        caption: Config.lang('Phong_ban'),
        dataField :'DepartmentName',
    },
    {
        caption: Config.lang('Nhom_luong'),
        dataField :'SalEmpGroupName',
    },
    {
        caption: Config.lang('Chuc_vu'),
        dataField :'DutyName',
    },
    {
        caption: Config.lang('Ngay_vao_lam'),
        dataField :'DateJoined',
        width: 150,
        alignment: "center",
        dataType:"date",
        format:"dd/MM/yyyy"
    },
    {
        caption: Config.lang('Du_an'),
        dataField :'ProjectName',
    },
    {
        caption: Config.lang('Ngay_lam_viec'),
        dataField :'WorkingDay',
        width: 150,
        alignment: "center",
        dataType:"date",
        format:"dd/MM/yyyy"
    },
    {
        caption: Config.lang('Ca'),
        dataField :'ShiftName',
    },
    {
        caption: Config.lang('Loai_ngay'),
        dataField :'WorkDayType',
    },
    {
        caption: Config.lang('Tang_ca_truoc'),
        alignment: "center",
        dataField: "PreOT",
        columns: [
            {
                caption: Config.lang('Ke_hoach'),
                alignment: "right",
                dataField :'PreOTHours',
            },
            {
                caption: Config.lang('Thuc_te'),
                alignment: "right",
                dataField :'OriPreOTHours',
            }
        ]
    },
    {
        caption: Config.lang('Tang_ca_giua'),
        alignment: "center",
        dataField: "BetOT",
        columns: [
            {
                caption: Config.lang('Ke_hoach'),
                alignment: "right",
                dataField :'BetOTHours',
            },
            {
                caption: Config.lang('Thuc_te'),
                alignment: "right",
                dataField :'OriBetOTHours',
            }
        ]
    },
    {
        caption: Config.lang('Tang_ca_sau'),
        alignment: "center",
        dataField: "AftOT",
        columns: [
            {
                caption: Config.lang('Ke_hoach'),
                alignment: "right",
                dataField :'AftOTHours',
            },
            {
                caption: Config.lang('Thuc_te'),
                alignment: "right",
                dataField :'OriAftOTHours',
            }
        ]
    },
];
const FormID = "W29F2300";
const Language = Config.language || "84";

const W29F2300 = () => {
    const {getCboShifts, getSalaryGroups, getCriteria} = useSelector(state => state.W29F2300 || {});
    const {getCboDuty2, getCboProjects, getCboAppStatus} = useSelector(state => state.general || {});

    const classes = useStyles();
    const dispatch = useDispatch();

    const [iPermission, setIPermission] = useState(0);
    const [dataFilter, setDataFilter] = useState(() => ({
        OrgChartID: "",
        SalEmpGroupID: "",
        DutyID: "",
        ProjectID: "",
        ShiftID: "",
        StatusID: 0,
        EmployeeID: "",
    }));
    const [rangeDate, setRangeDate] = useState([]);
    const [dataGrid, setDataGrid] = useState(() => ({rows: [], total: 0}));
    const [dataCboEmployees, setDataCboEmployees] = useState(() => ({rows: [], total: 0}));
    const [dataOrgChart, setDataOrgChart] = useState([]);
    const [dataGridExtraByCriteriaID, setDataGridExtraByCriteriaID] = useState({});
    const [cboCboEmployeesLoading, setCboCboEmployeesLoading] = useState(false);
    const [criteriaID, setCriteriaID] = useState(null);
    const [cboDutyLoading, setCboDutyLoading] = useState(false);
    const [cboProjectLoading, setCboProjectLoading] = useState(false);
    const [cboOrgChartLoading, setCboOrgChartLoading] = useState(false);
    const [cboSalEmpGroupLoading, setCboSalEmpGroupLoading] = useState(false);
    const [cboShiftLoading, setCboShiftLoading] = useState(false);
    const [gridLoading, setGridLoading] = useState(false);
    const [cboAppStatusLoading, setCboAppStatusLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isShowSelected, setIsShowSelected] = useState(false);
    const [iPermissionByTab, setIPermissionByTab] = useState(0);
    const [dataCboAppStatus, setDataCboAppStatus] = useState([]);
    const [loadingCriteria, setLoadingCriteria] = useState(false);

    const filterCboEmployees = useRef({
        search: "",
        skip: 0,
        limit: 50
    });
    const refGrid = useRef(null);
    const paramsPaging = useRef({
        skip: 0,
        limit: 20,
    });
    const refSelectedRowsData = useRef([]);
    const refSelectedRowKeys = useRef([]);
    const isSelectAll = useRef(false);
    const isChangePage = useRef(false);
    const isChangeShow = useRef(false);
    const isFilter = useRef(false);
    const refBtn = useRef(null);
    const timer = useRef(0);
    const conditionsSelectedRow = useRef([0, 4, 5]);

    const loadPermission = () => {
        dispatch(generalActions.getPermission("W29F2300", isPer => {
            setIPermission(isPer);
        }));
    };

    const loadPermissionByTab = (formID) => {
        dispatch(generalActions.getPermission(formID, isPer => {
            setIPermissionByTab(isPer);
        }, true));
    };

    const loadCboDuty = () => {
        setCboDutyLoading(true);
        dispatch(generalActions.getCboDuty2((error) => {
            setCboDutyLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
        }));
    };

    const loadDataOrgChart = () => {
        setCboOrgChartLoading(true);
        dispatch(generalActions.getOrgCharts({}, (error, data) => {
            setCboOrgChartLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setDataOrgChart(data || []);
        }));
    };

    const loadCboProjects = () => {
        const params = {
            HostID: "",
            FormID: FormID,
        };
        setCboProjectLoading(true);
        dispatch(generalActions.getCboProjects(params, error => {
            setCboProjectLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const loadCboAppStatus = () => {
        const params = {
            FormID,
            Language
        };
        setCboAppStatusLoading(true);
        dispatch(generalActions.getCboAppStatus(params, (err) => {
            setCboAppStatusLoading(false);
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        }))
    };

    const loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: FormID,
            Language: Config.language || "84",
            skip: filterCboEmployees.current.skip,
            limit: filterCboEmployees.current.limit,
            search: filterCboEmployees.current.search,
        };
        setCboCboEmployeesLoading(true);
        dispatch(generalActions.getCboEmployees(param, (error, data) => {
            setCboCboEmployeesLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const rows = data?.rows || [];
            const total = data?.total || 0;
            setDataCboEmployees({
                rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                total: total,
            });
        }));
    };

    const loadCboShift = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            OrgChartID: "",
            WorkDayType: "",
            AfterOTHours: 0,
            Language: Language,
            ProjectID: "",
        };
        setCboShiftLoading(true);
        dispatch(W29F2300Actions.getCboShift(params,(error) => {
            setCboShiftLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
        }));
    };

    const loadSalaryGroup = () => {
        setCboSalEmpGroupLoading(true);
        dispatch(W29F2300Actions.getSalaryGroups({},error => {
            setCboSalEmpGroupLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const loadCriteria = () => {
        const params = {
            FormID: FormID,
            Language: Language,
            Mode: 0,
        };
        setLoadingCriteria(true);
        dispatch(W29F2300Actions.getCriteria(params, (error, data) => {
            setLoadingCriteria(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            // xet default data button hien thi theo tung tab
            const dataColumn = data?.flatMap(m => m.GrpCriteria).reduce((obj, item) => {
                obj = {...obj, [`W29F2300_${item.CriteriaID}`]: dataColumnGridExtra()};
                return obj;
            }, {});
            setDataGridExtraByCriteriaID({...dataColumn})
        }));
    };

    const loadGrid = (isLoadGrid = false) => {
        if(!isLoadGrid) {
            isChangePage.current = true;
        }
        const params = {
            FormID: FormID,
            Language: Language,
            Mode: 0,
            CriteriaID: String(criteriaID),
            WorkingDayFrom: rangeDate?.length > 0 ? rangeDate[0] : null,
            WorkingDayTo: rangeDate?.length > 0 ? rangeDate[1] : null,
            OrgChartID: dataFilter?.OrgChartID.length > 0 ? JSON.stringify(dataFilter.OrgChartID) : "",
            SalEmpGroupID: dataFilter.SalEmpGroupID,
            DutyID: dataFilter.DutyID,
            ProjectID: dataFilter.ProjectID,
            ShiftID: dataFilter.ShiftID,
            StatusID: String(dataFilter.StatusID),
            EmployeeID: dataFilter.EmployeeID,
            skip: paramsPaging.current.skip,
            limit: paramsPaging.current.limit,

        };
        setGridLoading(true);
        dispatch(W29F2300Actions.getGridDetail(params, (error, data) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            let dataStatus = _.sortBy(_.uniqBy(data?.rows, d => d.StatusID), s => s.StatusID)
                                .map(d => ({StatusID: d.StatusID, StatusName: d.StatusName}));
            setDataCboAppStatus(dataStatus);
            setDataGrid({rows: data?.rows || [], total: data?.total || 0});
            if(isFilter.current) {
                isFilter.current = false;
                resetData();
            }
        }));
    };

    const onSave = () => {
        const params = {
            FormID: FormID,
            Language: Language,
            Mode: 0,
            CriteriaID: criteriaID,
            data: refSelectedRowsData.current.length > 0 ? JSON.stringify(refSelectedRowsData.current) : ""
        };
        setIsSaving(true);
        dispatch(W29F2300Actions.save(params, (error, data) => {
            setIsSaving(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data.Status === 1) {
                if(data.MsgAsk === 0) {
                    Config.popup.show('INFO', data.Message);
                    return false;
                }
            }
            if(data.Status === 0) {
                Config.notify.show('success', Config.lang("Thong_bao_thanh_cong"), 2000);
                resetData();
                loadGrid(true);
            }
        }));
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(!iPermission) return;
        loadCriteria();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    useEffect(() => {
         if(criteriaID !== null) {
             loadGrid(true);
         }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criteriaID]);

    useEffect(() => {
        if(!dataFilter.StatusID) _setDataFilter({StatusID: 0});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataFilter.StatusID]);

    useEffect(() => {
        if (isShowSelected) refGrid.current.instance.filter('IsSelected','=',1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isShowSelected]);

    useEffect(() => {
        setDisableBtnNotify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermissionByTab, isSaving, gridLoading, loadingCriteria]);

    const _setDataFilter = (obj) => {
        setDataFilter({...dataFilter, ...obj});
    };

    const filterChange = (key, evt) => {
        if(!key || !evt) return;
        const value = evt?.value ?? evt?.target?.value ?? "";
        if(key === "RangePicker") {
            const workingDayFrom = Array.isArray(value) ? value[0] : null;
            const workingDayTo = Array.isArray(value) ? value[1] : null;
            setRangeDate([workingDayFrom, workingDayTo]);
        } else {
            _setDataFilter({[key]: value});
        }
    };

    const setDataSelectedRowKeys = (e = {}, keyCompareChecked) => {
        const {currentSelectedRowKeys, selectedRowsData} = e;
        let deselectRowsKey = [];
        currentSelectedRowKeys.map(key => {
            if(refSelectedRowKeys.current.indexOf(key) < 0) {
                e.component.byKey(key).done(item => {
                    // đk để bỏ chọn những dòng disabled
                    if(!conditionsSelectedRow.current.includes(Number(item[keyCompareChecked]))) {
                        deselectRowsKey.push(key);
                    } else {
                        const rowData = selectedRowsData.find(f => f.TransID === key);
                        if(rowData) {
                            refSelectedRowsData.current.push(rowData);
                        }
                        refSelectedRowKeys.current.push(key);
                    }
                });
            }
            return key;
        });
        if(deselectRowsKey.length > 0) {
            e.component.deselectRows(deselectRowsKey);
        }
    };

    const onSelectionChanged = (e) => {
        // khi change page khong cho DeselectedRowKeys
        if(isChangePage.current) {
            isChangePage.current = false;
            return false;
        }
        // case: khi có data SelectedRowKeys => changepage => click checkbox isShowSelected => changepage => thì khong cho DeselectedRowKeys
        if(isChangeShow.current) {
            isChangeShow.current = false;
            return false;
        }
        const {currentSelectedRowKeys, selectedRowKeys, currentDeselectedRowKeys} = e;
        const total = e.component.totalCount();
        // su ly SelectAll và deselectAll
        if ((selectedRowKeys.length === total || selectedRowKeys.length === 0) && isSelectAll.current) {
            if (selectedRowKeys.length === total) e.component.deselectAll();
            if (selectedRowKeys.length === 0) isSelectAll.current = false;
        }
        isSelectAll.current = selectedRowKeys.length && (selectedRowKeys.length + currentDeselectedRowKeys.length) === total;
        // su ly chon tung dong
        if(currentDeselectedRowKeys.length > 0) {

            currentDeselectedRowKeys.map(key => {
                if(refSelectedRowKeys.current.includes(key) && refSelectedRowsData.current.length > 0) {
                    refSelectedRowsData.current = refSelectedRowsData.current.filter(f => f.TransID !== key);
                    refSelectedRowKeys.current = refSelectedRowKeys.current.filter(f => f !== key);
                }
                return key;
            });
        }
        else if(currentSelectedRowKeys.length > 0) {
          setDataSelectedRowKeys(e,"StatusID");
        }
    };

    const onChangePage = (page) => {
        paramsPaging.current.skip = page * paramsPaging.current.limit;
        loadGrid();
    };

    const onChangePerPage = (perPage) => {
        paramsPaging.current.skip = 0;
        paramsPaging.current.limit = perPage;
        loadGrid();
    };

    const resetData = () => {
        refSelectedRowsData.current = [];
        refSelectedRowKeys.current = [];
        isChangeShow.current = false;
        isChangePage.current = false;
        isSelectAll.current = false;
        isFilter.current = false;
        setIsShowSelected(false);
    };

    const onChangeTabItem = (dt) => {
        const {FormIDPermission, CriteriaID} = dt || {};
        loadPermissionByTab(FormIDPermission);
        resetData();
        setCriteriaID(Number(CriteriaID));
    };

    //true: Con key chua duoc select; false: da select het..
    const _checkSelectedRowsKeys = (dataSource) => {
        let isCheck = false;
        if (dataSource?.length > 0 && refSelectedRowKeys.current.length > 0) {
            const dataKeys = dataSource.map(d => d.TransID);
            for (let key of refSelectedRowKeys.current) {
                if (!dataKeys.includes(key)) {
                    isCheck = true;
                    break;
                }
            }
        }
        return isCheck;
    };

    const onLoadDataFilter = () => {
        loadDataOrgChart();
        loadCboDuty();
        loadCboProjects();
        loadCboEmployees();
        loadSalaryGroup();
        loadCboShift();
        loadCboAppStatus();
    };

    const renderFilter = () => {
        return (
            <Filter
                placeholder={Config.lang("Tim_kiem")}
                openOnFieldClick={true}
                isUseDDCore
                onOpenLoaded={onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <Row>
                                <Col xs={12}>
                                    <DateRangePicker
                                        showUnitCount={false}
                                        controls
                                        clearAble
                                        value={rangeDate}
                                        viewType={"outlined"}
                                        label={Config.lang("Ngay_lam_viec")}
                                        onChange={evt => filterChange("RangePicker", evt)}
                                        placeholder={"DD/MM/YY - DD/MM/YY"}
                                        returnFormat={"YYYY-MM-DD"}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        multiple
                                        clearAble
                                        loading={cboOrgChartLoading}
                                        dataSource={dataOrgChart}
                                        valueExpr={"OrgChartID"}
                                        displayExpr={"OrgName"}
                                        label={Config.lang("Co_cau_to_chuc")}
                                        keyExpr={'OrgName'}
                                        onChange={(e) => filterChange("OrgChartID", e)}
                                        value={dataFilter.OrgChartID}
                                        viewType={'outlined'}
                                        placeholder={Config.lang("Co_cau_to_chuc")}
                                    >
                                        <TreeView
                                            multiple
                                            allowSearch
                                            dataSource={dataOrgChart}
                                            disabledRelevantValue
                                            displayExpr={"OrgName"}
                                            id={"OrgChartID"}
                                            multipleValueMode="single"
                                            onChange={(e) => filterChange("OrgChartID", e)}
                                            parentID={"OrgChartParentID"}
                                            value={dataFilter.OrgChartID}
                                            valueExpr={"OrgChartID"}
                                        />
                                    </Dropdown>
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        loading={cboSalEmpGroupLoading}
                                        allowSearch
                                        label={Config.lang("Nhom_luong")}
                                        dataSource={getSalaryGroups}
                                        displayExpr={"{SalEmpGroupID} - {SalEmpGroupName}"}
                                        valueExpr={"SalEmpGroupID"}
                                        viewType={"outlined"}
                                        value={dataFilter.SalEmpGroupID}
                                        clearAble={true}
                                        onChange={evt => filterChange("SalEmpGroupID", evt)}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        loading={cboDutyLoading}
                                        allowSearch
                                        label={Config.lang("Chuc_vu")}
                                        dataSource={getCboDuty2}
                                        displayExpr={"{DutyID} - {DutyName}"}
                                        valueExpr={"DutyID"}
                                        viewType={"outlined"}
                                        value={dataFilter.DutyID}
                                        clearAble={true}
                                        onChange={evt => filterChange("DutyID", evt)}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        loading={cboProjectLoading}
                                        allowSearch
                                        label={Config.lang("Du_an")}
                                        dataSource={getCboProjects?.rows || []}
                                        displayExpr={"{ProjectID} - {ProjectName}"}
                                        valueExpr={"ProjectID"}
                                        viewType={"outlined"}
                                        value={dataFilter.ProjectID}
                                        clearAble={true}
                                        onChange={evt => filterChange("ProjectID", evt)}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        loading={cboShiftLoading}
                                        allowSearch
                                        label={Config.lang("Ca_lam_viec")}
                                        dataSource={getCboShifts}
                                        displayExpr={"{ShiftID} - {ShiftName}"}
                                        valueExpr={"ShiftID"}
                                        viewType={"outlined"}
                                        value={dataFilter.ShiftID}
                                        clearAble={true}
                                        onChange={evt => filterChange("ShiftID", evt)}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        defaultValue={0}
                                        allowSearch
                                        label={Config.lang("Trang_thai")}
                                        dataSource={getCboAppStatus}
                                        loading={cboAppStatusLoading}
                                        displayExpr={"{AppStatusID} - {AppStatusName}"}
                                        valueExpr={"AppStatusID"}
                                        viewType={"outlined"}
                                        value={dataFilter.StatusID}
                                        clearAble={true}
                                        onChange={evt => filterChange("StatusID", evt)}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        allowSearch
                                        clearAble
                                        dataSource={dataCboEmployees?.rows || []}
                                        total={dataCboEmployees?.total ?? 0}
                                        skip={filterCboEmployees.current.skip}
                                        limit={filterCboEmployees.current.limit}
                                        loading={cboCboEmployeesLoading}
                                        value={dataFilter.EmployeeID}
                                        viewType={"outlined"}
                                        displayExpr={'{EmployeeID} - {EmployeeName}'}
                                        keyExpr={'EmployeeID'}
                                        valueExpr={'EmployeeID'}
                                        subText={'EmployeeID'}
                                        label={Config.lang("Nhan_vien")}
                                        placeholder={Config.lang("Chon")}
                                        onChange={e => filterChange("EmployeeID", e)}
                                        onInput={(e) => {
                                            filterCboEmployees.current.search = e.target.value;
                                            filterCboEmployees.current.skip = 0;
                                            loadCboEmployees(true);
                                        }}
                                        onLoadMore={(e) => {
                                            filterCboEmployees.current.skip = e.skip;
                                            filterCboEmployees.current.limit = e.limit;
                                            loadCboEmployees();
                                        }}
                                    />
                                </Col>
                            </Row>
                            <div className={"display_row valign-middle mgt4x"}>
                                <Button
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"search"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    onClick={() => {
                                        isFilter.current = true;
                                        loadGrid(true)
                                    }}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const cellRenderStatus = (e) => {
        const {data} = e.row;
        return <Status data={data} keyExpr={"StatusID"} displayExpr={"StatusName"} />;
    };

    const renderButton = useMemo(() => {
        const _FormID = `W29F2300_${criteriaID}`;
        const data = dataGridExtraByCriteriaID[_FormID];
        return <PopoverShowHideCol
            data={data}
            FormID={_FormID}
            buttonProps={{
                className: "mgr2x",
                size     : "medium",
                disabled: isSaving || gridLoading || loadingCriteria,
            }}
            onSubmit={data => {
                setDataGridExtraByCriteriaID({
                    ...dataGridExtraByCriteriaID,
                    [_FormID]: data,
                });
                if (refGrid.current) refGrid.current.instance.repaint();
            }}
        />;
    },[dataGridExtraByCriteriaID, criteriaID, isSaving, gridLoading, loadingCriteria]);

    const setDisableBtnNotify = () => {
        let isDisabled =
            iPermissionByTab <= 1 ||
            isSaving ||
            gridLoading ||
            loadingCriteria;
        const hasSelectedRow = refSelectedRowKeys.current.length > 0;
        if(refBtn.current?.instance) {
            refBtn.current.instance.option("disabled", isDisabled || !hasSelectedRow);
        }
    };

    const dataGridExtra = dataGridExtraByCriteriaID[`W29F2300_${criteriaID}`];
    let _dataGrid = dataGrid.rows;
    if(isShowSelected) {
        if (refSelectedRowsData.current.length > 0) {
            const selectedRows = refGrid.current && refGrid.current.instance.getSelectedRowKeys();
            _dataGrid = refSelectedRowsData.current;
            // kiem tra hiển thị những dòng đã check và reset các biến bằng false cho Deselected Row Keys ở hàm onSelectionChanged
            if(selectedRows.length === refSelectedRowKeys.current.length) {
                isChangeShow.current = false;
                isSelectAll.current = false;
            }
        }
    } else if(isChangeShow.current) {
        // kiem tra trường hợp reset isChangeShow.current khi changepage chưa chọn dòng trên lưới sau đó click rồi bỏ click checkbox isShowSelected
        if(!_checkSelectedRowsKeys(_dataGrid)) isChangeShow.current = false;
    }
    _dataGrid = _dataGrid.map(d => ({...d, IsSelected: d.IsSelected ?? 0}));
    if (iPermission <= 0) return null;
    return (
        <>
            <ActionToolbar title={Config.lang("Du_lieu_bat_thuong")} />
            <div className={"hidden"}>{renderFilter()}</div>
            <Row className={classes.row}>
                <Col xs={12} sm={12} md={3} className={`${classes.leftColScroll} `} >
                    <div className={classes.leftCol}>
                        {getCriteria.length > 0 && getCriteria.map((m, idx) => {
                            return (
                                <Accordion key={m.GrpCriteriaID} expand={idx === 0}>
                                    <AccordionSummary>{m.GrpCriteriaName}</AccordionSummary>
                                    <AccordionDetails>
                                        <TabContainer tabIndex={criteriaID} direction={"vertical"}>
                                            <TabHeader>
                                                {m.GrpCriteria.length > 0 && m.GrpCriteria.map(f => {
                                                   return <TabItem
                                                       key={f.CriteriaID}
                                                       labelProps={{hoverTooltip: true,lineClamp: 1}}
                                                       onClick={() => onChangeTabItem(f)}
                                                       index={Number(f.CriteriaID)}
                                                       label={`(${f.NumCriteria}) ${f.CriteriaName}`} />
                                                })}
                                            </TabHeader>
                                        </TabContainer>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}

                    </div>
                </Col>
                <Col xs={12} sm={12} md={9} className={`${classes.col} ${classes.borderLeft}`}>
                    <div className={"mgt4x mgb4x display_row align-between align-center"}>
                        <Checkbox
                            checked={isShowSelected}
                            onChange={e => {
                                isChangeShow.current = true;
                                if(isChangePage.current) {
                                    isChangePage.current = false;
                                }
                                setIsShowSelected(e.value);
                            }}
                            disabled={isSaving || gridLoading || loadingCriteria}
                            label={Config.lang("Chi_hien_thi_nhung_du_lieu_da_chon")}
                        />
                        <div className={"display_row align-between align-center"}>
                            {renderButton}
                            <Button
                                disabled
                                color={"primary"}
                                viewType={"filled"}
                                label={Config.lang("Thong_bao")}
                                startIcon={"Notification"}
                                ref={refBtn}
                                onClick={onSave}
                            />
                        </div>
                    </div>
                    {dataGridExtra?.length > 0 &&
                        <GridContainer
                            keyExpr={"TransID"}
                            reference={ref => refGrid.current = ref}
                            dataSource={_dataGrid}
                            totalItems={dataGrid.total}
                            itemPerPage={paramsPaging.current.limit}
                            skipPerPage={paramsPaging.current.skip}
                            filterRow={{
                                visible             : true,
                                showOperationChooser: false,
                            }}
                            selection={{
                                mode              : 'multiple',
                                selectAllMode     : 'allPages',
                                showCheckBoxesMode: 'always',
                            }}
                            loading={gridLoading}
                            pagerFullScreen={false}
                            columnAutoWidth={true}
                            height={"calc(100vh - 230px)"}
                            showColumnLines={true}
                            typePaging={"remote"}
                            onSelectionChanged={onSelectionChanged}
                            onContentReady={(e) => {
                                setDisableBtnNotify();
                                if(timer.current) clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    e.component.selectRows(refSelectedRowKeys.current)
                                }, 300)
                            }}
                            onChangePage={onChangePage}
                            onChangePerPage={onChangePerPage}
                            onEditorPreparing={(e) => {
                                if (!e || !e.row) return;
                                const { data } = e.row;
                                if (e.parentType === "dataRow") {
                                    if ((!conditionsSelectedRow.current.includes(Number(data?.StatusID)))) {
                                        e.editorOptions.disabled = true;
                                    } else {
                                        e.editorOptions.onValueChanged = (evt) => {
                                            e.row.data.IsSelected = +evt.value;
                                            //refresh để chạy hàm filter lưới
                                            e.component.refresh();
                                        }
                                    }
                                }
                            }}
                        >
                            {dataGridExtra?.map((item, idx) => {
                                if (item.hasOwnProperty('isRenderStatus')) {
                                    return (
                                        <Column
                                            key={idx}
                                            lookup={{
                                                dataSource : dataCboAppStatus,
                                                valueExpr  : "StatusID",
                                                displayExpr: "StatusName",
                                            }}
                                            minWidth={200}
                                            dataField={item.dataField}
                                            visible={item.visible}
                                            caption={item.caption}
                                            cellRender={cellRenderStatus}
                                        />
                                    )
                                } else {
                                    return (
                                        <Column key={idx} {...item}/>
                                    )
                                }
                            })}
                            <Column
                                dataField={'EmployeeID'}
                                visible={false}
                            />
                            <Column
                                dataField={'DepartmentID'}
                                visible={false}
                            />
                            <Column
                                dataField={'DutyID'}
                                visible={false}
                            />
                            <Column
                                dataField={'ProjectID'}
                                visible={false}
                            />
                            <Column
                                dataField={'ShiftID'}
                                visible={false}
                            />
                            <Column
                                dataField={'SalEmpGroupID'}
                                visible={false}
                            />
                            <Column
                                dataField={'IsSelected'}
                                visible={false}
                            />
                        </GridContainer>
                    }
                </Col>
            </Row>
        </>
    )
};

export default W29F2300;
