/**
 * @copyright 2022 @ DigiNet
 * @author THANHAN
 * @create 10/10/2022
 * @Example
 */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from "react-redux";
import Config from "../../../../config";
import { makeStyles } from "diginet-core-ui/theme";
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Typography,
    Dropdown,
    ButtonIcon,
    Checkbox,
    TreeView,
    DateRangePicker
} from "diginet-core-ui/components";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import GridContainer from "../../../grid-container/grid-container";
import "./W29F2309.scss";
import * as W29F2309Actions from "../../../../redux/W2X/W29F2309/W29F2309_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { Column } from "devextreme-react/data-grid";

const useStyles = makeStyles((theme) => ({
    col: {
        height: "100%",
    },
    borderLeft: {
        borderLeft: `1px solid ${theme.colors?.line?.system}`,
    },
    leftColItem:{
        marginRight: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
            marginRight: 0
        }
    },
    actionToolbar:{
        marginLeft: -24,
        width: "unset",
        marginTop: "0px!important",
        backgroundColor: `${theme.colors.brand24}`,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        }
    },
    grid:{
        marginBottom: -theme.spacing(4),
        paddingBottom: theme.spacing(4),
    }
}))

const dataColumnGridExtra = () => [
    {
        caption: Config.lang('Nhan_vien'),
        width: 200,
        dataField: 'EmployeeName',
    },
    {
        caption: Config.lang('Ngay_lam_viec'),
        dataField: 'AttendanceDate',
        width: 150,
        alignment: "center",
        dataType: "date",
        format: "dd/MM/yyyy"
    },
    {
        caption: Config.lang('Tang_ca_truoc'),
        alignment: "center",
        dataField: "PreOT",
        columns: [
            {
                caption: Config.lang('Ke_hoach'),
                alignment: "right",
                dataField: 'PreOTHours',
            },
            {
                caption: Config.lang('Thuc_te'),
                alignment: "right",
                dataField: 'OriPreOTHours',
            },
            {
                caption: Config.lang('Dieu_chinh'),
                alignment: "right",
                dataField: 'CPreOTHours',
            }
        ]
    },
];

const FormID = "W29F2308";
const Language = Config.language || "84";

const W29F2308 = React.memo((props) => {
    const {
        open,
        onClose,
        iPermission,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [dataFilter, setDataFilter] = useState(() => ({
        OrgChartID: [],
        ProjectID: "",
        ShiftID: "",
        EmployeeID: "",
        DateFrom: null,
        DateTo: null,
    }));
    let errorMessage = "";
    let counter = 0;

    const [rangeDate, setRangeDate] = useState([]);
    const [dataGrid, setDataGrid] = useState(() => ({rows: [], total: 0}));
    const [dataCboEmployees, setDataCboEmployees] = useState(() => ({ rows: [], total: 0 }));
    const [isShowBetOT, setIsShowBetOT] = useState(0);
    const [dataOrgChart, setDataOrgChart] = useState([]);

    const [cboCboEmployeesLoading, setCboCboEmployeesLoading] = useState(false);
    const [cboOrgChartLoading, setCboOrgChartLoading] = useState(false);
    const [gridLoading, setGridLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isShowSelected, setIsShowSelected] = useState(false);

    const filterCboEmployees = useRef({
        search: "",
        skip: 0,
        limit: 50
    });
    const refGrid = useRef(null);
    const paramsPaging = useRef({
        skip: 0,
        limit: 20,
    });
    const refSelectedRowsData = useRef([]);
    const refSelectedRowKeys = useRef([]);
    const isSelectAll = useRef(false);
    const isChangePage = useRef(false);
    const isChangeShow = useRef(false);
    const isFilter = useRef(false);
    const timer = useRef(0);

    const [showDrawer, setShowDrawer] = useState(true);

    const loadGrid = (isLoadGrid = false) => {
        if (!isLoadGrid) {
            isChangePage.current = true;
        }
        const params = {
            FormID: FormID,
            DateFrom: rangeDate?.length > 0 ? rangeDate[0] : null,
            DateTo: rangeDate?.length > 0 ? rangeDate[1] : null,
            OrgChartID: dataFilter?.OrgChartID.length > 0 ? dataFilter.OrgChartID : [],
            EmployeeID: dataFilter.EmployeeID,
            Skip: paramsPaging.current.skip,
            Limit: paramsPaging.current.limit,

        };
        setGridLoading(true);
        dispatch(W29F2309Actions.loadForm(params, (error, data) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setDataGrid({ rows: data?.rows || [], total: data?.total || 0 });
            resetData();
        }));
    }

    const loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: FormID,
            Language,
            skip: filterCboEmployees.current.skip,
            limit: filterCboEmployees.current.limit,
            search: filterCboEmployees.current.search,
        };
        setCboCboEmployeesLoading(true);
        dispatch(generalActions.getCboEmployees(param, (error, data) => {
            setCboCboEmployeesLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const rows = data?.rows || [];
            const total = data?.total || 0;
            setDataCboEmployees({
                rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                total: total,
            });
        }));
    };

    const getGridSetting = () => {
        dispatch(W29F2309Actions.getGridSetting((error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setIsShowBetOT(!!data.IsBetweenOT)
        }));
    }

    const loadDataOrgChart = () => {
        setCboOrgChartLoading(true);
        dispatch(generalActions.getOrgCharts({}, (error, data) => {
            setCboOrgChartLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setDataOrgChart(data || []);
        }));
    };

    const setDataSelectedRowKeys = (e = {}, keyCompareChecked) => {
        const {currentSelectedRowKeys, selectedRowsData} = e;
        let deselectRowsKey = [];
        currentSelectedRowKeys.map(key => {
            if(refSelectedRowKeys.current.indexOf(key) < 0) {
                e.component.byKey(key).done(item => {
                    // đk để bỏ chọn những dòng disabled
                    const rowData = selectedRowsData.find(f => f.TransID === key);
                    if(rowData) {
                        refSelectedRowsData.current.push(rowData);
                    }
                    refSelectedRowKeys.current.push(key);
                });
            }
            return key;
        });
        if(deselectRowsKey.length > 0) {
            e.component.deselectRows(deselectRowsKey);
        }
    };

    const onSelectionChanged = (e) => {
        // khi change page khong cho DeselectedRowKeys
        if(isChangePage.current) {
            isChangePage.current = false;
            return false;
        }
        // case: khi có data SelectedRowKeys => changepage => click checkbox isShowSelected => changepage => thì khong cho DeselectedRowKeys
        if(isChangeShow.current) {
            isChangeShow.current = false;
            return false;
        }
        const {currentSelectedRowKeys, selectedRowKeys, currentDeselectedRowKeys} = e;
        const total = e.component.totalCount();
        // su ly SelectAll và deselectAll
        if ((selectedRowKeys.length === total || selectedRowKeys.length === 0) && isSelectAll.current) {
            if (selectedRowKeys.length === total) e.component.deselectAll();
            if (selectedRowKeys.length === 0) isSelectAll.current = false;
        }
        isSelectAll.current = selectedRowKeys.length && (selectedRowKeys.length + currentDeselectedRowKeys.length) === total;
        // su ly chon tung dong
        if(currentDeselectedRowKeys.length > 0) {
            currentDeselectedRowKeys.map(key => {
                if(refSelectedRowKeys.current.includes(key) && refSelectedRowsData.current.length > 0) {
                    refSelectedRowsData.current = refSelectedRowsData.current.filter(f => f.TransID !== key);
                    refSelectedRowKeys.current = refSelectedRowKeys.current.filter(f => f !== key);
                }
                return key;
            });
        }
        else if(currentSelectedRowKeys.length > 0) {
            setDataSelectedRowKeys(e);
        }
    };

    const onChangePage = (page) => {
        paramsPaging.current.skip = page * paramsPaging.current.limit;
        loadGrid();
    };

    const onChangePerPage = (perPage) => {
        paramsPaging.current.skip = 0;
        paramsPaging.current.limit = perPage;
        loadGrid();
    };

    //true: Con key chua duoc select; false: da select het..
    const _checkSelectedRowsKeys = (dataSource) => {
        let isCheck = false;
        if (dataSource?.length > 0 && refSelectedRowKeys.current.length > 0) {
            const dataKeys = dataSource.map(d => d.TransID);
            for (let key of refSelectedRowKeys.current) {
                if (!dataKeys.includes(key)) {
                    isCheck = true;
                    break;
                }
            }
        }
        return isCheck;
    };

    const onLoadDataFilter = () => {
        loadDataOrgChart();
        loadCboEmployees();
    };

    const resetData = () => {
        refSelectedRowsData.current = [];
        refSelectedRowKeys.current = [];
        isChangeShow.current = false;
        isChangePage.current = false;
        isSelectAll.current = false;
        setDataFilter(() => ({
            OrgChartID: [],
            ProjectID: "",
            ShiftID: "",
            EmployeeID: "",
            DateFrom: null,
            DateTo: null,
        }));
        isFilter.current = false;
        setIsShowSelected(false);
    };

    const _setDataFilter = (obj) => {
        setDataFilter({ ...dataFilter, ...obj });
    };

    const filterChange = (key, evt) => {
        if (!key || !evt) return;
        const value = evt?.value ?? evt?.target?.value ?? "";
        if (key === "RangePicker") {
            const DayFrom = Array.isArray(value) ? value[0] : null;
            const DayTo = Array.isArray(value) ? value[1] : null;
            setRangeDate([DayFrom, DayTo]);
        } else {
            _setDataFilter({ [key]: value });
        }
    };

    const _onClose = (reload = false) => {
        onClose && onClose(reload);
    }

    useEffect(() => {
        getGridSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isShowBetOT])

    useEffect(() => {
        onLoadDataFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isShowSelected) refGrid.current.instance.filter('IsSelected','=',1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isShowSelected]);

    let _dataGrid = dataGrid.rows;

    if(isShowSelected) {
        if (refSelectedRowsData.current.length > 0) {
            const selectedRows = refGrid.current && refGrid.current.instance.getSelectedRowKeys();
            _dataGrid = refSelectedRowsData.current;
            // kiem tra hiển thị những dòng đã check và reset các biến bằng false cho Deselected Row Keys ở hàm onSelectionChanged
            if(selectedRows.length === refSelectedRowKeys.current.length) {
                isChangeShow.current = false;
                isSelectAll.current = false;
            }
        }
    } else if(isChangeShow.current) {
        // kiem tra trường hợp reset isChangeShow.current khi changepage chưa chọn dòng trên lưới sau đó click rồi bỏ click checkbox isShowSelected
        if(!_checkSelectedRowsKeys(_dataGrid)) isChangeShow.current = false;
    }
    _dataGrid = _dataGrid.map(d => ({...d, IsSelected: d.IsSelected ?? 0}));
    if (iPermission <= 0) return null;

    const renderGrid = useMemo(() => {
        return (
            <GridContainer
                keyExpr={"TransID"}
                reference={ref => refGrid.current = ref}
                dataSource={_dataGrid}
                totalItems={dataGrid.total}
                itemPerPage={paramsPaging.current.limit}
                skipPerPage={paramsPaging.current.skip}
                selection={{
                    allowSelectAll: true,
                    mode: 'multiple',
                    selectAllMode: 'allPages',
                    showCheckBoxesMode: 'always',
                }}
                onContentReady={(e) => {
                    if(timer.current) clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                        e.component.selectRows(refSelectedRowKeys.current)
                    }, 300)
                }}
                noDataText={Config.lang("No_data")}
                loading={gridLoading}
                pagerFullScreen={false}
                height={656}
                showColumnLines={true}
                typePaging={"remote"}
                onSelectionChanged={onSelectionChanged}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                onEditorPreparing={(e) => {
                    if (!e || !e.row) return;
                    e.editorOptions.onValueChanged = (evt) => {
                        e.row.data.IsSelected = +evt.value;
                        //refresh để chạy hàm filter lưới
                        e.component.refresh()
                    }
                }}
            >
                {dataColumnGridExtra().map((item, idx) => {
                    return (
                        <Column key={idx} {...item} />
                    )
                })}
                <Column
                    caption={Config.lang('Tang_ca_giua')}
                    alignment="center"
                    dataField={"BetOT"}
                    visible={isShowBetOT}
                >
                    <Column
                        caption={Config.lang('Ke_hoach')}
                        alignment={"right"}
                        dataField={'BetOTHours'}
                    />
                    <Column
                        caption={Config.lang('Thuc_te')}
                        alignment={"right"}
                        dataField={'OriBetOTHours'}
                    />
                    <Column
                        caption={Config.lang('Dieu_chinh')}
                        alignment={"right"}
                        dataField={'CBetOTHours'}
                    />
                </Column>
                <Column
                    caption={Config.lang('Tang_ca_sau')}
                    alignment="center"
                    dataField={"AftOT"}
                >
                    <Column
                        caption={Config.lang('Ke_hoach')}
                        alignment={"right"}
                        dataField={'AftOTHours'}
                    />
                    <Column
                        caption={Config.lang('Thuc_te')}
                        alignment={"right"}
                        dataField={'OriAftOTHours'}
                    />
                    <Column
                        caption={Config.lang('Dieu_chinh')}
                        alignment={"right"}
                        dataField={'CAftOTHours'}
                    />
                </Column>
                <Column
                    dataField={'EmployeeID'}
                    visible={false}
                />
                <Column
                    dataField={'ApprovalFlowID'}
                    visible={false}
                />
                <Column
                    dataField={'ApprovalLevel'}
                    visible={false}
                />
                <Column
                    dataField={'TransID'}
                    visible={false}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_dataGrid, refSelectedRowKeys.current, gridLoading])

    const onSave =  (mode) => {
        const approvalStatus = mode === 0 ? "1" : "100";
        const arrCheckParams = [];
        const arrApprovalParams = [];
        if(refSelectedRowsData.current.length > 0){
            setGridLoading(true);
            setIsSaving(true);
            refSelectedRowsData.current.forEach((data) => {
                const {
                    CPreOTHours,
                    CBetOTHours,
                    CAftOTHours,
                    EmployeeID,
                    AttendanceDate,
                    ApprovalLevel,
                    ApprovalFlowID,
                    TransID
                } = data;
                const checkParams = {
                    FormID,
                    CPreOTHours,
                    CBetOTHours,
                    CAftOTHours,
                    EmployeeID,
                    WorkingDay: AttendanceDate,
                    Mode: mode
                }
                const approvalParams = {
                    FormID,
                    VoucherID: TransID,
                    AttendanceDate,
                    ApprovalLevel,
                    ApprovalFlowID,
                    ApprovalStatus: approvalStatus,
                    ApprovalNotes:"",
                    Mode: 0,
                };
                arrCheckParams.push(checkParams);
                arrApprovalParams.push(approvalParams);
            });
            let arrParams = { arrCheckParams, arrApprovalParams };
            const checkParamsLength = arrParams.arrCheckParams.length;
            const approvalTimeout = checkParamsLength <5 ? 1000 : Math.ceil(checkParamsLength /10) * 1500;
            dispatch(W29F2309Actions.check(arrParams, (error, data) => {
                counter++;
                const isLastRequest = (counter === arrParams.arrCheckParams.length);
                const lastRequestHanlder = (data) => {
                    if (isLastRequest) {
                        if (errorMessage) {
                            const lastMessage = Config.lang(`Ton_tai_nhung_don_tu_${mode === 0 ? "duyet" : "tu_choi"}_khong_thanh_cong:`) + `${errorMessage}\n${data.Message}`;
                            setTimeout(() => Config.popup.show("INFO", lastMessage, () => {
                                arrParams = [];
                                loadGrid();
                            }),approvalTimeout);
                        }
                        else {
                            setTimeout(() => Config.popup.show("INFO", Config.lang(`${mode === 0 ? "Duyet" : "Tu_choi"}_thanh_cong`), () => {
                                arrParams = [];
                                loadGrid();
                            }),approvalTimeout);
                        }
                    }
                }
        
                if (isLastRequest) {
                    setTimeout(()=> {
                        setIsSaving(false);
                        setGridLoading(false);
                    },approvalTimeout)
                }
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                else if (data.Status === 1) {
                    if (data.MsgAsk === 0) {
                        errorMessage = `${errorMessage}\n${data.Message}`; // MsgAsk = 0 thì UI giữ lại để sau khi thực hiện hết quy trình sẽ xuất ra Message = Message của các dòng có Status = 1 Và MsgAsk = 0 cộng lại cách nhau bởi dấu xuống dòng
                        if (isLastRequest) {
                            const lastMessage = Config.lang(`Ton_tai_nhung_đon_tu_${mode === 0 ? "duyet" : "tu_choi"}_khong_thanh_cong:`) + `${errorMessage}\n${data.Message}`;
                            Config.popup.show("INFO", lastMessage, () => {
                                arrParams = [];
                                loadGrid();
                            });
                        }
                    }
                    else if (data.MsgAsk === 1) {
                        Config.popup.show(
                            "YES_NO",
                            data?.Message, 
                            () => { // Yes
                                dispatch(W29F2309Actions.approval(arrParams.arrApprovalParams[counter - 1], error => {
                                    if (error) {
                                        Config.popup.show("ERROR", error);
                                    }
                                    lastRequestHanlder(data);
                                }
                                ))
                            },
                            () => { // No
                                dispatch(W29F2309Actions.cancelApproval(() => {
                                    lastRequestHanlder(data);
                                }))
                            } 
                        );
                    }
                }
                else {
                    lastRequestHanlder(data);
                }
            }));
        }
        else {
            Config.popup.show("INFO", Config.lang("Ban_chua_chon_ke_hoach"));
        }
    }

    return (
        <Modal width={1024} open={open} onClose={()=> _onClose(true)}>
            <ModalHeader>
                {Config.lang("Duyet_thay_doi_ke_hoach_tang_ca_hang_loat")}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} sm={12} md={showDrawer ? 4 : 1}>
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <ActionToolbar
                                    alignment={showDrawer ? "space-between" : "center"}
                                    showBorder={false}
                                    upperCase={false}
                                    className={classes.actionToolbar}
                                    allwaysTop={false}
                                >
                                    <div
                                        className={"display_row align-center valign-middle"}
                                    >
                                        <ButtonIcon
                                            name="FilterFilled"
                                            viewType="text"
                                            circular
                                            color="dark"
                                            size="small"
                                            onClick={() => setShowDrawer(!showDrawer)}
                                        />
                                        {showDrawer &&
                                            <Typography>{Config.lang("Tieu_chi_loc")}</Typography>
                                        }
                                    </div>
                                    {showDrawer &&
                                        <ButtonIcon
                                            name="ArrowLeft"
                                            circular
                                            viewType="text"
                                            onClick={() => setShowDrawer(false)}
                                        />
                                    }
                                </ActionToolbar>
                            </Col>
                            {showDrawer &&
                                <>
                                    <Col xs={12} sm={12} md={12}>
                                        <Checkbox
                                            style={{ marginTop: 2 }}
                                            checked={isShowSelected}
                                            onChange={e => {
                                                isChangeShow.current = true;
                                                if(isChangePage.current) {
                                                    isChangePage.current = false;
                                                }
                                                setIsShowSelected(e.value);
                                            }}
                                            disabled={isSaving || gridLoading}
                                            label={Config.lang("Chi_hien_thi_nhung_du_lieu_da_chon")}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <Dropdown
                                            className={classes.leftColItem}
                                            allowSearch
                                            clearAble
                                            dataSource={dataCboEmployees?.rows || []}
                                            total={dataCboEmployees?.total ?? 0}
                                            skip={filterCboEmployees.current.skip}
                                            limit={filterCboEmployees.current.limit}
                                            loading={cboCboEmployeesLoading}
                                            value={dataFilter.EmployeeID}
                                            viewType={"underlined"}
                                            displayExpr={'{EmployeeID} - {EmployeeName}'}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            subText={'EmployeeID'}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => filterChange("EmployeeID", e)}
                                            onInput={(e) => {
                                                filterCboEmployees.current.search = e.target.value;
                                                filterCboEmployees.current.skip = 0;
                                                loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterCboEmployees.current.skip = e.skip;
                                                filterCboEmployees.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <Dropdown
                                            className={classes.leftColItem}
                                            multiple
                                            clearAble
                                            loading={cboOrgChartLoading}
                                            dataSource={dataOrgChart}
                                            valueExpr={"OrgChartID"}
                                            displayExpr={"OrgName"}
                                            label={Config.lang("Co_cau_to_chuc")}
                                            keyExpr={'OrgName'}
                                            onChange={(e) => filterChange("OrgChartID", e)}
                                            value={dataFilter.OrgChartID}
                                            viewType={'underlined'}
                                            placeholder={Config.lang("Chon")}
                                        >
                                            <TreeView
                                                multiple
                                                allowSearch
                                                dataSource={dataOrgChart}
                                                disabledRelevantValue
                                                displayExpr={"OrgName"}
                                                id={"OrgChartID"}
                                                multipleValueMode="single"
                                                onChange={(e) => filterChange("OrgChartID", e)}
                                                parentID={"OrgChartParentID"}
                                                value={dataFilter.OrgChartID}
                                                valueExpr={"OrgChartID"}
                                            />
                                        </Dropdown>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <DateRangePicker
                                            className={classes.leftColItem}
                                            controls
                                            clearAble
                                            value={rangeDate}
                                            viewType={"underlined"}
                                            label={Config.lang("Ngay_lam_viec")}
                                            onChange={evt => filterChange("RangePicker", evt)}
                                            placeholder={"dd/mm/yyyy - dd/mm/yyyy"}
                                            returnFormat={"YYYY-MM-DD"}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12}
                                        className={"display_row valign-bottom"}
                                    >
                                        <Button
                                            color={"primary"}
                                            className={`${classes.leftColItem} mgt2x`}
                                            startIcon={"search"}
                                            viewType={"outlined"}
                                            label={Config.lang("Tim_kiem")}
                                            onClick={()=>{
                                                isFilter.current = true;
                                                loadGrid();
                                            }}
                                        />
                                    </Col>
                                </>
                            }
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={showDrawer ? 8 : 11} className={`${classes.col} ${classes.borderLeft}`}>
                        {renderGrid}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={iPermission < 2}
                    startIcon={"Approval"}
                    viewType={"filled"}
                    label={Config.lang("Duyet")}
                    color={"success"}
                    className={"mgr2x"}
                    onClick={() => onSave(0)}
                />
                <Button
                    disabled={iPermission < 2}
                    startIcon={"Cancel"}
                    viewType={"filled"}
                    label={Config.lang("Tu_choi")}
                    color={"danger"}
                    onClick={() => onSave(1)}
                />
            </ModalFooter>
        </Modal>
    )
})

export default W29F2308;