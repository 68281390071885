/**
 * @copyright 2020 @ DigiNet
 * @author CANHTOAN
 * @create 7/24/2020
 * @Example 
 */

import React from "react";
import {FormGroup, Col, Row} from "react-bootstrap";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Approvals from "../../../approvals/approvals";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import { FormLabel as Label, withStyles } from "@material-ui/core";
import ButtonGeneral from "../../../common/button/button-general";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import W38F2002General from "./W38F2002General";

const styles = theme => ({
    heading: {
        textTransform: "uppercase",
        fontWeight: 500
    },
    ExpansionPanelSummary: {
        padding: 0,
        maxWidth: 300,
        color: theme.palette.primary.main,
        "& .MuiExpansionPanelSummary-content": {
            margin: "12px 0"
        },
        "&.Mui-expanded": {
            minHeight: "inherit"
        }
    },
});
class W38F2002 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataForm: {},
            VoucherID: "",
            cboEmployeeLoading: false,
            dataCboEmployees: {
                rows: [],
                total: 0
            }
        }
        this.filter = {
            Search: "",
            AppStatusID: null,
            Employee: null,
            DateFrom: null,
            DateTo: null,
            skip: 0,
            limit: 10
        }
        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W38F2002", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadCboEmployees();
        this.loadGrid();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getGridApproval} = this.props;
        Config.callChildForm({
            FormID: "W38F2002",
            // ID: voucher_id,
            keyExpr: "VoucherID",
            data: getGridApproval && getGridApproval.rows ? getGridApproval.rows : [],
            onLoad: (params) => this.loadGrid(params && params.ID),
            onAction: (e, data) => {
                const {ID} = e || "";
                this.refs['Approvals'].showPopup(data);
                this.loadFormInfo(ID);
            }
        }, this.props);
    }

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W38F2002",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    renderFormInfo = (data) => {
        return (
            <W38F2002General data={data}/>
        )
    };

    handleFilterChange = (key, e) => {
        if (key && e) {
            const value = e.value || e.target?.value;
            this.filter[key] = value;
        }
    };

    loadGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : (voucher_id ? voucher_id : "");
        const {AppStatusID, Employee, DateFrom, DateTo, skip, limit} = this.filter;
        const params = {
            VoucherID,
            DivisionID: Config.getDivisionID(),
            FormID: 'W38F2002',
            Language: Config.language || '84',
            ApprovalStatus: AppStatusID,
            EmployeeID: Employee && Employee.EmployeeID ? Employee.EmployeeID : "",
            DateFrom: DateFrom,
            DateTo: DateTo,
            skip: skip,
            limit: limit,
        };
        this.props.approvalActions.getGridApprovals(params, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboEmployees = (isReset) => {
        const {FormID} = this.props;
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: FormID,
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({cboEmployeeLoading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({cboEmployeeLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    renderFilter = () => {
        const {getCboStatus} = this.props;
        const {dataCboEmployees, cboEmployeeLoading} = this.state;
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <DateBoxPicker
                                                max={this.filter.DateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                width={"100%"}
                                                value={this.filter.DateFrom}
                                                onValueChanged={e => this.handleFilterChange("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <DateBoxPicker
                                                min={this.filter.DateFrom}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                width={"100%"}
                                                value={this.filter.DateTo}
                                                onValueChanged={e =>
                                                    this.handleFilterChange("DateTo", e)
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={getCboStatus}
                                            showClearButton={true}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"ApprovalStatus"}
                                            value={this.filter.AppStatusID}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            onValueChanged={e => this.handleFilterChange("AppStatusID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={this.filter.Employee}
                                            loading={cboEmployeeLoading}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Nguoi_lap')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilterChange("Employee", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral name={Config.lang('DHR_Tim_kiem')}
                                    typeButton={'search'}
                                    color={"primary"}
                                    className={"pdl10 pdr10"}
                                    variant={"outlined"}
                                    style={{textTransform: 'uppercase'}}
                                    size={"large"}
                                    onClick={this.loadGrid}/>
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    render() {
        const { getGridApproval } = this.props;
        const {iPermission, dataForm, loading} = this.state;
        if (!iPermission) return null;

        return (
            <React.Fragment>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Duyet_de_xuat_dao_tao")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                allowHistoryApproval
                                FormID={"W38F2002"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                disabled={loading}
                                dataSource={getGridApproval}
                                singleClick
                                showFilters={false}
                                formInfoRender={(e) => this.renderFormInfo(e)}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        );
    }

}

export default compose(connect(state => ({
    getCboStatus: state.approvals.getCboStatus,
    getGridApproval: state.approvals.getGridApproval,
    getAttachments: state.approvals.getAttachments,
    getHistorys: state.approvals.getHistorys,
}),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
), withStyles(styles, {withTheme: true}))(W38F2002);
