import React, { useEffect, useState, useRef, useMemo } from 'react';
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import GridContainer from "../../../grid-container/grid-container";
import { DateRangePicker, Dropdown, Button, Row, Col, FormGroup, Typography, ButtonIcon, TreeView } from 'diginet-core-ui/components';
import Filter from "../../../filter/filter";
import { makeStyles } from "diginet-core-ui/theme";
import _ from 'lodash';
import UserImage from "../../../common/user/user-image";
import { Column } from 'devextreme-react/data-grid';
import moment from 'moment';
import UserName from "../../../common/user/user-name";
import { CalendarNew } from 'diginet-core-ui/icons';
import Status from "../../../common/status/status";
import * as W29F2305Actions from "../../../../redux/W2X/W29F2305/W29F2305_actions";
import * as W29f2000Actions from "../../../../redux/W2X/W29F2000/W29F2000_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import W29F2306 from "../../../W2X/W29/W29F2306/W29F2306";

import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";

const useStyles = makeStyles(() => ({
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 8
    },
    divButton: {
        display: "flex",
        justifyContent: "center"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        fontWeight: 500,
    },
}));
const FormID = "W29F2305";
const Language = Config.language || "84";

const _w84F3005PopupData = {
    TransID: null
};
const _w29F2306PopupData = {
    TransID: null
};

const dataFilterGrid = {
    OrgChartID: "",
    SalEmpGroupID: "",
    DutyID: "",
    ShiftID: "",
    ProjectID: "",
    StatusID: null,
    EmployeeID: "",
    WorkingDayFrom: null,
    WorkingDayTo: null,
}

const _initFilter = {
    skip: 0,
    limit: 20
};

const _dataCboProjects = {
    rows: [],
    total: 0,
};

const _filterCboProjects = {
    strSearch: "",
    skip: 0,
    limit: 50,
};

const _loading = {
    OrgChartID: false,
    SalEmpGroupID: false,
    DutyID: false,
    ShiftID: false,
    ProjectID: false,
    StatusID: false,
    EmployeeID: false,
}

const W29F2305 = (props) => {

    const { getCboDuty2: getCboDuty = [], getOrgCharts: dataOrgCharts = [] } = useSelector(state => state?.general || {});
    const { getCboShifts } = useSelector(state => state?.W29F2000 || {});
    const [dataFilter, setDataFilter] = useState(dataFilterGrid);
    const [dataGrid, setDataGrid] = useState({ rows: [], total: 10 });
    const [isLoadingDataGrid, setLoadingDataGrid] = useState(false);
    const [loading, _setLoading] = useState(_loading);
    const [showW84F3005Popup, setShowW84F3005Popup] = useState(false);
    const [showW29F2306Popup, setShowW29F2306Popup] = useState(false);

    const [cbSalEmpGroup, setCbSalEmpGroup] = useState([]);
    const [cbAppStatus, setCbAppStatus] = useState([]);
    const [rangeDate, setRangeDate] = useState([]);
    const [dataCboProjects, setDataCboProjects] = useState(_dataCboProjects);
    const [dataCboEmployees, setDataCboEmployees] = useState(_dataCboProjects);
    const [iPermission, setIPermission] = useState(0);

    const w84F3005PopupData = useRef(_w84F3005PopupData);
    const w29F2306PopupData = useRef(_w29F2306PopupData);

    const filterGrid = useRef(_initFilter);
    const filterUser = useRef(_filterCboProjects);
    const filterCboEmployees = useRef(_filterCboProjects);


    const dispatch = useDispatch();
    const classes = useStyles();

    const setLoading = (obj) => {
        _setLoading({ ...loading, ...obj });
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        Config.callChildForm({
            FormID,
            keyExpr: "TransID",
            data: dataGrid && dataGrid.rows ? dataGrid.rows : [],
            onLoad: (params) => loadGrid(params && params.ID),
            onAction: (e, data) => {
                onShowW29F2306Poppup(data, "IconView")
            },
        }, props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
        if (iPermission) {
            // loadGrid();
            loadAppStatus();
            getSalEmpGroup();
            loadDataOrgChart();
            loadCboDuty();
            loadCboShifts();
            loadCboProjects();
            loadCboEmployees();

            const { voucher_id } = Config.getUrlParams(props);
            if (!voucher_id) loadGrid();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    const loadPermission = () => {
        dispatch(generalActions.getPermission('W29F2305', (isPer) => {
            setIPermission(isPer);
        })
        );
    };

    const loadGrid = (TransID = "") => {
        const params = {
            TransID: TransID,
            FormID,
            Mode: 0,
            CriteriaID: "",
            WorkingDayFrom: dataFilter.WorkingDayFrom,
            WorkingDayTo: dataFilter.WorkingDayTo,
            OrgChartID: JSON.stringify(dataFilter.OrgChartID) ?? "",
            SalEmpGroupID: dataFilter.SalEmpGroupID,
            DutyID: dataFilter.DutyID,
            ProjectID: dataFilter.ProjectID,
            ShiftID: dataFilter.ShiftID,
            StatusID: dataFilter.StatusID || null,
            EmployeeID: dataFilter.EmployeeID,
            skip: filterGrid.current.skip,
            limit: filterGrid.current.limit,
        };

        setLoadingDataGrid(true);
        dispatch(
            W29F2305Actions.loadGridData(params, (err, data) => {
                setLoadingDataGrid(false);
                if (err) {
                    Config.popup.show("ERROR", err);
                    return false;
                } else if (data) {
                    const rows = _.get(data, "rows", []);
                    const total = _.get(data, "total", 0);
                    setDataGrid({ rows, total });
                }
            }))
    };

    const getSalEmpGroup = () => {
        setLoading({ SalEmpGroupID: true })
        dispatch(
            W29F2305Actions.getSalEmpGroup((err, data) => {
                setLoading({ SalEmpGroupID: false })
                if (err) {
                    Config.popup.show("ERROR", err);
                    return false;
                } else if (data) {
                    setCbSalEmpGroup(data)
                }
            }))
    };

    const loadAppStatus = () => {
        const params = {
            FormID,
            Language,
        }
        setLoading({ StatusID: true })
        dispatch(W29F2305Actions.getAppStatus(params, (err, data) => {
            setLoading({ StatusID: false })
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            } else if (data) {
                setCbAppStatus(data)
            }
        }))
    };

    const loadDataOrgChart = () => {
        setLoading({ OrgChartID: true })
        dispatch(generalActions.getOrgCharts({}, (error) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const loadCboDuty = () => {
        setLoading({ DutyID: true })
        dispatch(generalActions.getCboDuty2(error => {
            setLoading({ DutyID: false })
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const loadCboShifts = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            OrgChartID: dataFilter.OrgChartID,
            WorkDayType: "",
            AfterOTHours: 0,
            Language,
            ProjectID: dataFilter.ProjectID,
        };
        setLoading({ ShiftID: true })
        dispatch(W29f2000Actions.getCboShift(params, (errors) => {
            setLoading({ ShiftID: false })
            if (errors) {
                Config.popup.show("ERROR", errors);
                return false;
            }
        }))
    };

    const loadCboProjects = isReset => {
        const param = {
            HostID: "",
            FormID,
            Language,
            skip: filterUser.skip,
            limit: filterUser.limit,
            search: filterUser.strSearch,
        };
        setLoading({ ProjectID: true })

        dispatch(generalActions.getCboProjects(param, (error, data) => {
            setLoading({ ProjectID: false })
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                setDataCboProjects({
                    rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                    total: total
                })
            }
        }));
    };

    const loadCboEmployees = (isReset) => {
        const params = {
            Type: "EmployeeID",
            FormID,
            Language,
            skip: filterCboEmployees.current.skip,
            limit: filterCboEmployees.current.limit,
            search: filterCboEmployees.current.strSearch
        };
        setLoading({ EmployeeID: true })
        dispatch(generalActions.getCboEmployees(params, (error, data) => {
            setLoading({ EmployeeID: false })
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total
                })
            }
        }))
    };


    const handleChange = (key, e) => {
        if (!key) return false;
        const value = e?.value ?? e?.target?.value ?? "";
        if (key === "RangePicker") {
            const dateFrom = Array.isArray(value) ? value[0] : null;
            const dateTo = Array.isArray(value) ? value[1] : null;
            setDataFilter({
                ...dataFilter,
                WorkingDayFrom: dateFrom,
                WorkingDayTo: dateTo
            });
            setRangeDate([dateFrom, dateTo]);
        } else {
            setDataFilter({ ...dataFilter, [key]: value })
        }
    };

    const onHistoryApproval = (data = {}) => {
        w84F3005PopupData.current.TransID = data.TransID;
        setShowW84F3005Popup(true);
    };

    const onShowW29F2306Poppup = (data = {}, key) => {
        w29F2306PopupData.current.TransID = data?.TransID;
        w29F2306PopupData.current.EmployeeID = data?.EmployeeID;
        switch (key) {
            case "IconView":
                if (data?.StatusID === 0 || data?.StatusID === 3) {
                    w29F2306PopupData.current.mode = "edit"
                } else {
                    w29F2306PopupData.current.mode = "view"
                }
                break;
            case "doubleClick":
                w29F2306PopupData.current.mode = "view"
                break;
            default:
                break;
        }
        setShowW29F2306Popup(true);
    };

    const onDelete = (e) => {
        if (!e) return false;
        const { data } = e.row;
        const params = {
            FormID,
            Mode: 2,
            TransID: data?.TransID || ""
        };
        Config.popup.show('YES_NO', Config.lang("Ban_co_chac_chan_xoa"),
            () => {
                dispatch(
                    W29F2305Actions.deleteDaTa(params, (errors, data) => {
                        if (errors) {
                            Config.popup.show('ERROR', errors);
                            return false;
                        }
                        else if (data) {
                            if(data.Status === 1) {
                                Config.popup.show('INFO', data.Message);
                                return false;
                            }
                            else if(data.Status === 0) {
                                Config.notify.show('success', Config.lang("Xoa_thanh_cong"), 2000);
                                loadGrid();
                            }

                        }
                    }))
            });
    };

    const onChangePage = (page) => {
        filterGrid.current.skip = page * filterGrid.current.limit;
        loadGrid();
    };

    const onChangePerPage = (perPage) => {
        filterGrid.current.skip = 0;
        filterGrid.current.limit = perPage;
        loadGrid();
    };

    const onSearch = () => {
        loadGrid();
    };
    const renderFilter = () => {
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                showHeader={true}
                isUseDDCore={true}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12}>
                                        <DateRangePicker
                                            clearAble
                                            controls
                                            value={rangeDate}
                                            viewType={"outlined"}
                                            label={Config.lang("Ngay_lam_viec")}
                                            onChange={e => handleChange("RangePicker", e)}
                                            placeholder={"DD/MM/YY - DD/MM/YY"}
                                            returnFormat={"YYYY-MM-DD"}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Dropdown
                                            multiple
                                            clearAble
                                            keyExpr={"OrgName"}
                                            viewType={"outlined"}
                                            displayExpr={"OrgName"}
                                            valueExpr={"OrgChartID"}
                                            label={Config.lang("Co_cau_to_chuc")}
                                            placeholder={Config.lang("Chon")}
                                            loading={loading.OrgChartID}
                                            dataSource={dataOrgCharts ?? []}
                                            value={dataFilter.OrgChartID || []}
                                            onChange={(e) => handleChange("OrgChartID", e)}
                                        >
                                            <TreeView
                                                multiple
                                                allowSearch
                                                id={"OrgChartID"}
                                                displayExpr={"OrgName"}
                                                valueExpr={"OrgChartID"}
                                                multipleValueMode="single"
                                                parentID={"OrgChartParentID"}
                                                dataSource={dataOrgCharts ?? []}
                                                value={dataFilter.OrgChartID || []}
                                                onChange={(e) => handleChange("OrgChartID", e)}
                                            />
                                        </Dropdown>
                                    </Col>
                                    <Col xs={12}>
                                        <Dropdown
                                            allowSearch
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"SalEmpGroupID"}
                                            displayExpr={"{SalEmpGroupID} - {SalEmpGroupName}"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Nhom_luong")}
                                            onChange={e => handleChange("SalEmpGroupID", e)}
                                            loading={loading.SalEmpGroupID}
                                            dataSource={cbSalEmpGroup}
                                            value={dataFilter.SalEmpGroupID}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"DutyID"}
                                            loading={loading.DutyID}
                                            displayExpr={"{DutyID} - {DutyName}"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Chuc_vu")}
                                            onChange={e => handleChange("DutyID", e)}
                                            dataSource={getCboDuty}
                                            value={dataFilter.DutyID}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataCboProjects.rows}
                                            total={dataCboProjects.total}
                                            skip={filterUser.current.skip}
                                            limit={filterUser.current.limit}
                                            displayExpr={"{ProjectID} - {ProjectName}"}
                                            valueExpr={'ProjectID'}
                                            value={dataFilter.ProjectID}
                                            loading={loading.ProjectID}
                                            viewType={'outlined'}
                                            label={Config.lang('Du_an')}
                                            onChange={(e) => handleChange("ProjectID", e)}
                                            onInput={(e) => {
                                                filterUser.current.strSearch = e.target.value;
                                                filterUser.current.skip = 0;
                                                loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterUser.current.skip = e.skip;
                                                filterUser.current.limit = e.limit;
                                                loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"ShiftID"}
                                            loading={loading.ShiftID}
                                            displayExpr={"{ShiftID} - {ShiftName}"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Ca_lam_viec")}
                                            onChange={e => handleChange("ShiftID", e)}
                                            dataSource={getCboShifts}
                                            value={dataFilter.ShiftID}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"AppStatusID"}
                                            displayExpr={"{AppStatusID} - {AppStatusName}"}
                                            renderSelectedItem={"AppStatusName"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Trang_thai")}
                                            onChange={e => handleChange("StatusID", e)}
                                            loading={loading.StatusID}
                                            dataSource={cbAppStatus}
                                            value={dataFilter.StatusID}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataCboEmployees.rows}
                                            total={dataCboEmployees.total}
                                            skip={filterCboEmployees.current.skip}
                                            limit={filterCboEmployees.current.limit}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            valueExpr={'EmployeeID'}
                                            value={dataFilter.EmployeeID}
                                            loading={loading.EmployeeID}
                                            viewType={'outlined'}
                                            label={Config.lang('Nhan_vien')}
                                            onChange={(e) => handleChange("EmployeeID", e)}
                                            onInput={(e) => {
                                                filterCboEmployees.current.strSearch = e.target.value;
                                                filterCboEmployees.current.skip = 0;
                                                loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterCboEmployees.current.skip = e.skip;
                                                filterCboEmployees.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <div className={classes.divButton}>
                                    <Button
                                        className={"mgt3x"}
                                        size={"medium"}
                                        color={"primary"}
                                        viewType={"outlined"}
                                        label={Config.lang("Tim_kiem")}
                                        startIcon={"Search"}
                                        onClick={onSearch}
                                    />
                                </div>
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    const renderEmpProfile = e => {
        const { data } = e.row;
        const user = Config.getUser({ EmployeeID: data?.EmployeeID || "" });
        const dateJoined = user ? moment(user.DateJoined).isValid() ? moment(user.DateJoined).format('DD/MM/YYYY') : user.DateJoined : "";
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div className={classes.divAvatar}>
                    <UserImage data={data} />
                </div>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data} /></div>
                    <div className={classes.divText}>{user && user.DepartmentName ? user.DepartmentName : ""}</div>
                    <div className={classes.dateInfo}>
                        <CalendarNew
                            height={20}
                            viewBox
                            width={20}
                        />
                        <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                            {user && dateJoined}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderInfo = e => {
        const { data } = e.row;
        return (
            <div className={"pdt10 pdb10"}>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.Details) }} />
                {data.Reason && (
                    <Typography lineClamp={1} hoverTooltip>
                        {Config.lang("Ly_do")}: {data.Reason}
                    </Typography>
                )}
                {data.Note && (
                    <Typography lineClamp={1} hoverTooltip>
                        {Config.lang("Ghi_chu")}: {data.Note}
                    </Typography>
                )}
            </div>
        );
    };

    const renderStatus = e => {
        const { data } = e.row;
        return <Status data={data} keyExpr={"StatusID"} displayExpr={"StatusName"} />;
    };

    const renderAction = e => {
        const { data } = e.row;
        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                    circular
                    viewBox
                    viewType={"text"}
                    className={"mgr1x"}
                    name={"history"}
                    disabled={iPermission <= 1}
                    onClick={() => onHistoryApproval(data)}
                />
                <ButtonIcon
                    circular
                    name={"View"}
                    viewType={"text"}
                    className={"mgr1x"}
                    disabled={iPermission <= 1}
                    onClick={() => onShowW29F2306Poppup(data, "IconView")}
                />
                <ButtonIcon
                    circular
                    viewBox
                    viewType={"text"}
                    name={"delete"}
                    disabled={iPermission <= 3 || (data?.StatusID !== 0 && data?.StatusID !== 3 && data?.StatusID !== 2 )}
                    onClick={() => onDelete(e)}
                />
            </div>
        );
    };

    const renderGrid = useMemo(() => {
        return (
            <GridContainer
                keyExpr={"TransID"}
                style={{ border: "none" }}
                dataSource={dataGrid.rows}
                totalItems={dataGrid.total}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                loading={isLoadingDataGrid}
                height={Config.getHeightGrid()}
                itemPerPage={filterGrid.current.limit}
                skipPerPage={filterGrid.current.skip}
                typePaging={"remote"}
                allowColumnResizing={true}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                hoverStateEnabled={true}
                onRowDblClick={(e) => onShowW29F2306Poppup(e.data, "doubleClick")}
            >
                <Column cellRender={renderEmpProfile} width={250} />
                <Column cellRender={renderInfo} />
                <Column cellRender={renderStatus} alignment={"center"} width={200} />
                <Column cellRender={renderAction} width={200} />
            </GridContainer>
        )
        // eslint-disable-next-line
    }, [dataGrid, isLoadingDataGrid, filterGrid]);
    return (
        <>
            <FormGroup>
                <div className={"hidden"}>{renderFilter()}</div>
                <ActionToolbar title={Config.lang("Thay_doi_ke_hoach_tang_ca")} />
                <W84F3005 open={showW84F3005Popup} onClose={() => setShowW84F3005Popup(false)}
                    FormID={FormID}
                    TransID={w84F3005PopupData.current.TransID} />

                {showW29F2306Popup && <W29F2306 open={showW29F2306Popup} onClose={(isLoading = false) => {
                    setShowW29F2306Popup(false);
                    isLoading && loadGrid();
                }}
                    FormID={FormID}
                    TransID={w29F2306PopupData.current.TransID}
                    EmployeeID={w29F2306PopupData.current.EmployeeID}
                    mode={w29F2306PopupData.current.mode}
                    iPermission={iPermission}
                />}
                {renderGrid}
            </FormGroup>
        </>
    );
}

export default W29F2305;
