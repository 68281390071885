/**
 * @copyright 2022 @ DigiNet
 * @author THANHAN
 * @create 10/10/2022
 * @Example
 */

import React, { useState, useEffect } from 'react'
import Config from "../../../../config";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import { useTheme, makeStyles } from "diginet-core-ui/theme";
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    TextInput,
    Chip,
    Typography,
    Divider,
    Avatar,
    HelperText
} from "diginet-core-ui/components";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as W29F2306Actions from "../../../../redux/W2X/W29F2306/W29F2306_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import moment from 'moment';
const { colors, spacing } = useTheme();

const useStyles = makeStyles((theme) => ({
    backgroundInfo: {
        backgroundColor: theme.colors.info1,
    },
    backgroundSuccess: {
        backgroundColor: theme.colors.success1,
    },
    backgroundWarning: {
        backgroundColor: theme.colors.warning1,
    },
    textField: {
        margin: `-${spacing(2)}px 0 ${spacing(2)}px 0`,
        width: 75,
    }
}))

const W29F2306 = (props) => {
    const classes = useStyles();
    const {
        open,
        mode,
        EmployeeID,
        TransID,
        onClose,
        iPermission
    } = props;

    let checkRule = {};
    const [dataGrid, setDataGrid] = useState({});
    const isView = mode === "view";
    let isAllNotEmpty =
        (dataGrid?.PreOTFrom && dataGrid?.PreOTFrom) ||
        (dataGrid?.BetOTFrom && dataGrid?.BetOTTo) ||
        (dataGrid?.AftOTTo && dataGrid?.AftOTTo);

    const arrOT = ["TCT", "TCG", "TCS"];
    const arrTime = ["Pre", "Bet", "Aft"];
    const [errorMask, setErrorMask] = useState({
        CPreOTFrom: false,
        CPreOTTo: false,
        CBetOTFrom: false,
        CBetOTTo: false,
        CAftOTFrom: false,
        CAftOTTo: false,
    });

    const [loading, setLoading] = useState(false);
    const [isChange, setIsChange] = useState(false);


    const insertColon = (data) => { // chuyển các chuỗi gio` dạng 1234 => 12:34
        if (!data) return null;
        else {
            return moment(data, "HHmm").format("HH:mm");
        }
    };

    const removeColon = (data) => { // chuyển các chuỗi gio` dạng 12:34 => 1234
        if (!data) return null;
        else {
            return moment(data, "HH:mm").format("HHmm");
        }
    }

    const formatDay = (dateString) => { //format from DD/MM/YYYY to YYYY-MM-DD
        if (!dateString) return null;
        else {
            return moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
        };
    };

    const renderInfo = () => {
        const user = Config.getUser({ EmployeeID });
        const arrInfoEmployee = [
            {
                title: "Nhan_vien",
                data: user?.EmployeeName,
            },
            {
                title: "Phong_ban",
                data: user?.DepartmentName,
            },
            {
                title: "Ngay_vao_lam",
                data: Config.convertDate(user?.DateJoined, null, "DD/MM/YYYY"),
            }];

        return (
            <div className={"display_row"}>
                <Avatar
                    height={64}
                    width={64}
                    readOnly
                    className={"mgr4x"}
                    src={user?.UserPictureURL}
                />
                <div className="display_col">
                    {
                        arrInfoEmployee.map((info) => {
                            return (
                                <div className={"display_row"} key={info?.title}>
                                    <Typography type={"heading3"} className={"mgr1x"}>
                                        {Config.lang(info?.title)}:
                                    </Typography>
                                    <Typography>
                                        {info?.data ?? ""}
                                    </Typography>
                                </div>
                            )
                        }
                        )
                    }
                </div>
            </div>
        )
    };

    const renderDayInfo = () => {
        const {
            WorkingDay,
            WorkDayType,
            ShiftName,
            SPreOTFrom,
            SPreOTTo,
            SBetOTFrom,
            SBetOTTo,
            SAftOTFrom,
            SAftOTTo,
        } = dataGrid;

        return !loading ? (
            <div className={"display_col mgb2x"}>
                <Typography type={"heading3"}>
                    {`${WorkingDay ? `${WorkingDay} - ` : ""}`}{WorkDayType}
                </Typography>
                <Typography className={"display_row"}>
                    {ShiftName ?? ""}
                </Typography>
                {arrOT.map((OTType, i) => {
                    let OTInfo = "";
                    switch (i) {
                        case 0:
                            if (!SPreOTFrom && !SPreOTTo) {
                                break;
                            }
                            OTInfo = `${insertColon(SPreOTFrom)} - ${insertColon(SPreOTTo)}`;
                            break;
                        case 1:
                            if (!SBetOTFrom && !SBetOTTo) {
                                break;
                            }
                            OTInfo = `${insertColon(SBetOTFrom)} - ${insertColon(SBetOTTo)}`;
                            break;
                        case 2:
                            if (!SAftOTFrom && !SAftOTTo) {
                                break;
                            }
                            OTInfo = `${insertColon(SAftOTFrom)} - ${insertColon(SAftOTTo)}`;
                            break;
                        default:
                            break;
                    }
                    return (
                        <Typography
                            key={`OTType${i}`}
                        >
                            {`${OTInfo ? `${OTType}: ${OTInfo}` : ""}`}
                        </Typography>
                    )
                })}
            </div>
        ) : ""
    };

    const renderChips = () => {
        const arrChips = [];
        const arrIcons = ["OneSquare", "TwoSquare", "ThreeSquare", "FourSquare", "FiveSquare"];
        let counter = 0; // number of chips
        for (let i = 1; i <= 5; i++) {
            if (dataGrid?.[`TimeOn${i}`] && dataGrid?.[`TimeOff${i}`] !== "") { // 
                counter += 1;
                const timeOn = insertColon(dataGrid?.[`TimeOn${i}`])
                const timeOff = insertColon(dataGrid?.[`TimeOff${i}`])
                arrChips.push(
                    {
                        label: `${timeOn} - ${timeOff}`,
                        icon: arrIcons[counter - 1],
                    }
                )
            }
        };

        return (
            <>
                {arrChips.map((chip, index) => {
                    return (
                        <Chip
                            key={`chip${index}`}
                            startIcon={
                                chip?.icon
                            }
                            color={"dark"}
                            viewType={"outlined"}
                            label={chip?.label}
                            size={"large"}
                            className={"mgr1x"}
                        />
                    )
                })}
            </>
        )
    };

    const loadGrid = () => {
        const params = {
            FormID: "W29F3000",
            Mode: 0,
            CriteriaID: "1",
            TransID,
        }
        setLoading(true);
        props.w29f2306Actions.loadGrid(params, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("INFO", error);
                return false;
            }
            else if (data) {
                setDataGrid(data);
            }
        })
    };

    /**
     * 
     * @param {string} type in ["CPre","CBet","CAft"]
     * @returns
     */
    const getOTHour = async (type) => {
        const action = `${type.toLowerCase()}`; // ["cpre", "cbet", "caft"]
        const getOTKeyFrom = `${type}OTFrom`;
        const getOTKeyTo = `${type}OTTo`;
        const { WorkingDay, ShiftID } = dataGrid;
        const params = {
            AttendanceDate: formatDay(WorkingDay),
            EmployeeID,
            ShiftID,
            [getOTKeyFrom]: removeColon(dataGrid?.[getOTKeyFrom]),
            [getOTKeyTo]: removeColon(dataGrid?.[getOTKeyTo]),
        }
        if (
            !dataGrid?.[getOTKeyFrom] || //check value = null
            !dataGrid?.[getOTKeyTo] ||
            !isChange // check state change to prevent request API onBlur
        ) {
            return;
        }
        else if (
            !dataGrid?.[getOTKeyFrom]?.includes("_") && // check value includes "_"
            !dataGrid?.[getOTKeyTo]?.includes("_")
        ) {
            setLoading(true);
            props.w29f2306Actions[action](params, (error, data) => {
                setLoading(false);
                setIsChange(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                else if (data) {
                    const oldDataGrid = dataGrid;
                    if(data.Status === 1) {
                        Config.popup.show("INFO", data.Message);
                        setDataGrid({ ...oldDataGrid, [`${type}OTHours`]: 0 }) // ex: CPreOTHours : 0
                        return;
                    }
                    else {
                        setDataGrid({ ...oldDataGrid, [`${type}OTHours`]: data?.OTHour }) // ex: CPreOTHours : 1
                    }
                }
            })
        }
    };

    const _checkMaskRules = data => {
        if (!data) return null;
        return data?.startsWith("2");
    };

    const handleChange = (key, e) => {
        const value = e?.target?.value ?? e?.value ?? "";
        setIsChange(true);
        setDataGrid({ ...dataGrid, [`${key}`]: value });
    };

    const onSave = async () => {
        const saveMode = mode === "add" ? 0 : 1;
        const {
            CPreOTHours,
            CBetOTHours,
            CAftOTHours,
            WorkingDay,
            CPreOTFrom,
            CPreOTTo,
            CBetOTFrom,
            CBetOTTo,
            CAftOTFrom,
            CAftOTTo,
            Note,
            Reason
        } = dataGrid;
        const timeIsValid = 
            !CPreOTFrom.includes("_") &&
            !CPreOTTo.includes("_") &&
            !CBetOTFrom.includes("_") &&
            !CBetOTTo.includes("_") &&
            !CAftOTFrom.includes("_") &&
            !CAftOTTo.includes("_");

        if(timeIsValid) {
            const params = {
                TransID,
                Mode: saveMode,
                FormID: "W29F2305",
                CPreOTHours: CPreOTHours ?? 0,
                CBetOTHours: CBetOTHours ?? 0,
                CAftOTHours: CAftOTHours ?? 0,
                EmployeeID,
                WorkingDay: formatDay(WorkingDay),
                CPreOTFrom: removeColon(CPreOTFrom) ?? "",
                CPreOTTo: removeColon(CPreOTTo) ?? "",
                CBetOTFrom: removeColon(CBetOTFrom) ?? "",
                CBetOTTo: removeColon(CBetOTTo) ?? "",
                CAftOTFrom: removeColon(CAftOTFrom) ?? "",
                CAftOTTo: removeColon(CAftOTTo) ?? "",
                Note: Note ?? "",
                Reason: Reason ?? "",
            }
            setLoading(true);
            props.w29f2306Actions.save(params, (error, data) => {
                setLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                else if (data) {
                    if(data?.Status === 1) {
                        Config.popup.show("INFO", data.Message);
                    }
                    else {
                        onClose && onClose(true);
                        Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    }
                }
            })
        }

    };

    useEffect(() => {
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal width={960} open={open} onClose={onClose}>
            <ModalHeader>
                {Config.lang("Thay_doi_ke_hoach_tang_ca")}
            </ModalHeader>
            <ModalBody>
                <Row>
                    {/* avatar, ten nhan vien, phong ban, ngay vao lam */}
                    <Col xs={12} sm={6} md={6}>
                        {renderInfo()}
                    </Col>
                    {/* thong tin ngay lam */}
                    <Col xs={12} sm={6} md={6}>
                        {renderDayInfo()}
                    </Col>
                    <Col xs={12} sm={12} md={12} className={"display_row align-center wrap"}>
                        <Typography
                            type={"heading3"}
                            uppercase
                            center
                            className={"mgr3x"}
                        >
                            {Config.lang("Gio_vao")} - {Config.lang("ra")}:
                        </Typography>
                        {renderChips()}
                    </Col>
                    {/* title */}
                    <Col xs={2} sm={2} md={2} />
                    {isAllNotEmpty &&
                        <>
                            <Col xs={2} sm={2} md={2}>
                                <Typography
                                    color={colors.info}
                                    uppercase
                                    center
                                    type={"heading3"}
                                    className={"mgt4x"}
                                >
                                    {Config.lang("Thuc_te")}
                                </Typography>
                            </Col>
                            <Col xs={4} sm={4} md={4} >
                                <Typography
                                    color={colors.success}
                                    uppercase
                                    center
                                    type={"heading3"}
                                    className={"mgt4x"}
                                >
                                    {Config.lang("Ke_hoach")}
                                </Typography>
                            </Col>
                            <Col xs={4} sm={4} md={4}>
                                <Typography
                                    color={colors.warning}
                                    uppercase
                                    center
                                    type={"heading3"}
                                    className={"mgt4x"}
                                >
                                    {Config.lang("Dieu_chinh")}
                                </Typography>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <Divider color={colors.system.dark} />
                            </Col>
                        </>
                    }
                    {/* grid */}
                    {arrOT.map((OTType, index) => {
                        const keyTime = arrTime[index]; // "Pre, Bet, Aft"

                        const planFrom = insertColon(dataGrid?.[`${keyTime}OTFrom`]); // "PreOTFrom, BetOTFrom, AftOTFrom"
                        const planTo = insertColon(dataGrid?.[`${keyTime}OTTo`]); // "PreOTTo, BetOTTo, AftOTTo"
                        const planHour = dataGrid?.[`${keyTime}OTHours`]; // "PreOTHours, ..."
                        const planOTIsNotEmpty = planFrom && planTo; // check for empty

                        const keyFrom = `C${keyTime}OTFrom`; // "CPreOTFrom, CBetOTFrom, CAftOTFrom"
                        const keyTo = `C${keyTime}OTTo`; // "CPreOTTo, CBetOTTo, CAftOTTo"
                        const keyHour = dataGrid?.[`C${keyTime}OTHours`]; // "CPreOTHours, ..."
                        checkRule[keyFrom] = dataGrid?.[keyFrom] && _checkMaskRules(dataGrid?.[keyFrom]);
                        checkRule[keyTo] = dataGrid?.[keyTo] && _checkMaskRules(dataGrid?.[keyTo]);

                        return (
                            <React.Fragment key={`grid${index}`}>
                                {planOTIsNotEmpty &&
                                    <Col xs={2} sm={2} md={2}>
                                        <Typography
                                            type={"heading3"}
                                            center
                                            className={`pdb5x`}
                                        >
                                            {OTType}
                                        </Typography>
                                    </Col>
                                }
                                {/* Cột Thực tế */}
                                {planOTIsNotEmpty &&
                                    <Col xs={2} sm={2} md={2}
                                        className={`${classes.backgroundInfo} display_row align-center valign-middle`}
                                    >
                                        <Typography
                                            center
                                            className={`pdb3x`}
                                        >
                                            {`${dataGrid?.[`Ori${keyTime}OTHours`]}h`}
                                        </Typography>
                                    </Col>
                                }
                                {/* Cột Kế  Hoạch*/}
                                {planOTIsNotEmpty &&
                                    <Col xs={4} sm={4} md={4}
                                        className={`${classes.backgroundSuccess} display_row align-center valign-middle`}
                                    >
                                        <>
                                            <Typography
                                                center
                                                className={`pdb3x`}
                                            >
                                                {planFrom} - {planTo}
                                            </Typography>
                                            <Typography
                                                center
                                                style={{minWidth: spacing(15)}}
                                                className={`pdb3x mgl4x`}
                                            >
                                                {planHour}h
                                            </Typography>
                                        </>
                                    </Col>
                                }
                                {/* Cột Điều Chỉnh*/}
                                {planOTIsNotEmpty &&
                                    <Col xs={4} sm={4} md={4}
                                        className={`${classes.backgroundWarning} display_row align-center valign-middle`}
                                        style={{ position: "relative" }}
                                    >
                                            <InputMask
                                                mask={"12:34"}
                                                maskChar={loading ? "" : "_"}
                                                alwaysShowMask={true}
                                                formatChars={{
                                                    1: "[0-2]",
                                                    2: checkRule[keyFrom] ? "[0-3]" : "[0-9]",
                                                    3: "[0-5]",
                                                    4: "[0-9]",
                                                }}
                                                onChange={e => handleChange(keyFrom, e)}
                                                value={dataGrid?.[keyFrom]}
                                                readOnly={isView}
                                                onBlur={async () => {
                                                    if (dataGrid?.[keyFrom]) {
                                                        if (
                                                            dataGrid?.[keyFrom]
                                                                .split("")
                                                                .some(val => val === "_")
                                                        ) {
                                                            setErrorMask({
                                                                ...errorMask,
                                                                [keyFrom]: true,
                                                            });
                                                        } else {
                                                            setErrorMask({
                                                                ...errorMask,
                                                                [keyFrom]: false,
                                                            });
                                                        }
                                                    }
                                                    if (!isView)
                                                        await getOTHour(`C${keyTime}`);
                                                }}
                                                onKeyDown={e => {
                                                    if (
                                                        e?.keyCode === 9 &&
                                                        !isView
                                                    ) {
                                                        getOTHour(`C${keyTime}`);
                                                    }
                                                }}
                                            >
                                                {props => (
                                                    <TextField
                                                        {...props}
                                                        className={classes.textField}
                                                        variant={"outlined"}
                                                        InputProps={{
                                                            readOnly: isView
                                                        }}
                                                    />
                                                )}
                                            </InputMask>
                                            <span className="mgh1x mgb4x">-</span>
                                            <InputMask
                                                mask={"12:34"}
                                                alwaysShowMask={true}
                                                maskChar={loading ? "" : "_"}
                                                formatChars={{
                                                    1: "[0-2]",
                                                    2: checkRule[keyTo] ? "[0-3]" : "[0-9]",
                                                    3: "[0-5]",
                                                    4: "[0-9]",
                                                }}
                                                onChange={e => handleChange(keyTo, e)}
                                                value={dataGrid?.[keyTo]}
                                                readOnly={isView}
                                                onBlur={() => {
                                                    if (dataGrid?.[keyTo]) {
                                                        if (
                                                            dataGrid?.[keyTo]
                                                                .split("")
                                                                .some(val => val === "_")
                                                        ) {
                                                            setErrorMask({
                                                                ...errorMask,
                                                                [keyTo]: true,
                                                            });
                                                        } else {
                                                            setErrorMask({
                                                                ...errorMask,
                                                                [keyTo]: false,
                                                            });
                                                        }
                                                    }
                                                    if (!isView)
                                                        getOTHour(`C${keyTime}`);
                                                }}
                                                onKeyDown={e => {
                                                    if (
                                                        e?.keyCode === 9 &&
                                                        !isView
                                                    ) {
                                                        getOTHour(`C${keyTime}`);
                                                    }
                                                }}
                                            >
                                                {props => (
                                                    <TextField
                                                        {...props}
                                                        className={classes.textField}
                                                        variant={"outlined"}
                                                        InputProps={{
                                                            readOnly: isView
                                                        }}
                                                    />
                                                )}
                                            </InputMask>
                                            <Typography
                                                center
                                                style={{minWidth: spacing(15)}}
                                                className={`pdb3x mgl4x`}
                                            >
                                                {keyHour}h
                                            </Typography>
                                            {(errorMask?.[keyFrom] || errorMask?.[keyTo]) &&
                                                <HelperText style={{ position: "absolute", top: spacing(10) }}>
                                                    {Config.lang("Ban_phai_nhap_dung_dinh_dang_vd_11:11")}
                                                </HelperText>}
                                    </Col>
                                }
                                {(planOTIsNotEmpty && index !== 2) &&
                                    <Col xs={12} sm={12} md={12} style={{ paddingTop: 0 }}>
                                        <Divider />
                                    </Col>
                                }
                            </React.Fragment>
                        )
                    })
                    }
                    {/*  */}
                    <Col xs={12} sm={12} md={12}>
                        <TextInput
                            readOnly={isView}
                            value={dataGrid?.Reason}
                            onChange={(e) => { handleChange("Reason", e) }}
                            label={Config.lang("Ly_do")}
                            viewType={"underlined"}
                            className={"mgt2x"}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <TextInput
                            readOnly={isView}
                            value={dataGrid?.Note}
                            onChange={(e) => { handleChange("Note", e) }}
                            label={Config.lang("Ghi_chu")}
                            viewType={"underlined"}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={isView || iPermission < 2}
                    startIcon={"save"}
                    viewType={"filled"}
                    label={Config.lang("Luu")}
                    color={"info"}
                    onClick={onSave}
                    loading={loading}
                />
            </ModalFooter>
        </Modal>
    )
}

export default compose(
    connect(
        () => ({}),
        (dispatch) => ({
            w29f2306Actions: bindActionCreators(W29F2306Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W29F2306);