/**
 * @copyright 2020 @ DigiNet
 * @author CANHTOAN
 * @create 7/24/2020
 * @Example 
 */

import React from "react";
import PropTypes from "prop-types";
import {FormGroup, Col, Row} from "react-bootstrap";
import Config from "../../../../config";
import LabelText from "../../../common/label-text/label-text";
import {withStyles, FormControl, InputLabel} from "@material-ui/core";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import * as _ from "lodash";

const styles = {
    ExpansionPanelSummary: {
        margin: 0,
        minHeight: 0,
        "&.Mui-expanded": {
            minHeight: 0
        },
        "& .MuiExpansionPanelSummary-content": {
            margin: 0,
            "&.Mui-expanded": {
                margin: 0
            }
        }
    },
    shrink: {
        transform: 'translate(0, 1.5px) scale(1)',
    },
};

class W38F2002General extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            Employee: []
        }
    }

    render() {
        const {data, classes} = this.props;
        if (!Object.keys(this.props.data).length) return null;
        const Employee = data.Employee || [];

        let FromDate = _.get(data, "FromDate", "");
        FromDate = FromDate ? Config.convertDate(FromDate, "", "DD/MM/YYYY", (/t.*?z/i).test(FromDate)) : '';
        let ToDate = _.get(data, "ToDate", "");
        ToDate = ToDate ? Config.convertDate(ToDate, "", "DD/MM/YYYY", (/t.*?z/i).test(ToDate)) : '';
        const hyphen = FromDate && ToDate ? ' - ' : '';
        const TimeTrain = FromDate + hyphen + ToDate;
        const IsInternalName = _.get(data, "IsInternalName", "");
        return (
            <div style={{width: '100%'}} id={'formInfo'}>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_Linh_vuc_dao_tao")}
                                allowPadding={true}
                                value={_.get(data, "TrainingFieldName", "")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_Khoa_dao_tao")}
                                allowPadding={true}
                                value={_.get(data, "TrainingCourseName", "")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_Thoi_gian_dao_tao")}
                                allowPadding={true}
                                value={TimeTrain}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_So_luong_de_xuat")}
                                allowPadding={true}
                                value={_.get(data, "ProNumber", "")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_Nguoi_dao_tao")}
                                allowPadding={true}
                                value={_.get(data, "TrainningEmpName", "") + ' ' + IsInternalName}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_Du_an")}
                                allowPadding={true}
                                value={_.get(data, "ProjectName", "")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_Phong_ban")}
                                allowPadding={true}
                                value={_.get(data, "DepartmentName", "")}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_To_nhom")}
                                allowPadding={true}
                                value={_.get(data, "TeamName", "")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_Ly_do_dao_tao")}
                                allowPadding={true}
                                multiline
                                // rows={'auto'}
                                value={_.get(data, "TrainingPurpose", "")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <LabelText
                        label={Config.lang("DHR_Noi_dung_dao_tao")}
                        allowPadding={true}
                        multiline
                        // rows={'auto'}
                        value={_.get(data, "Content", "")}
                        fullWidth={true}
                    />
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_So_gio_dao_tao")}
                                allowPadding={true}
                                value={_.get(data, "TrainingPeriod", "")}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_So_thang_dao_tao")}
                                allowPadding={true}
                                value={_.get(data, "TrainingMonthNum", "")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_Tong_chi_phi")}
                                allowPadding={true}
                                value={new Intl.NumberFormat().format(_.get(data, "ProCost", "")) + ' ' + _.get(data, "ProCurrencyName", "")}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_Tong_chi_phi_quy_doi")}
                                allowPadding={true}
                                style={{textTransform: "lowercase"}}
                                value={new Intl.NumberFormat().format(_.get(data, "ProCCost", "")) + ' VNĐ'}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("DHR_Ghi_chu")}
                                allowPadding={true}
                                value={_.get(data, "ProNote", "")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className={"mgb15"}>
                        <FormControl fullWidth={true}>
                            <InputLabel
                                shrink={true}
                                style={{
                                    fontSize: '1rem',
                                    fontWeight: 200,
                                }}
                                classes={{shrink: classes.shrink}}
                                focused={false}
                            >
                                {Config.lang("DHR_Danh_sach_nhan_vien")}
                            </InputLabel>
                            <GridContainer
                                // onDbCellClick={e => this.redirectScreen("view", e)}
                                // selectedRowKey={selectedRowKeys}
                                height={45*5+45}
                                style={{ margin: '16px 0px 0px 0px' }}
                                dataSource={Employee}
                                totalItems={Employee.length}
                                showBorders={true}
                                showColumnLines={true}
                                // hoverStateEnabled={true}
                            >
                                <Column
                                    dataField={"NumOrder"}
                                    caption={Config.lang("DHR_STT").toUpperCase()}
                                    alignment={"center"}
                                    paddingLeft={0}
                                    width={50} />
                                <Column dataField={"EmployeeID"} caption={Config.lang("DHR_Ma_nhan_vien")} />
                                <Column dataField={"EmployeeName"} caption={Config.lang("DHR_Ten_nhan_vien")} />
                                <Column dataField={"DutyName"} caption={Config.lang("DHR_Chuc_vu")} />
                            </GridContainer>
                        </FormControl>
                        </Col>
                    </Row>
                </FormGroup>
            </div>
        );
    }

}

W38F2002General.propTypes = {
    data: PropTypes.object
};

export default withStyles(styles)(W38F2002General);
