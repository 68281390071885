import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as GeneralActions from "../../../../redux/general/general_actions";
import * as W29F2120Actions from "../../../../redux/W2X/W29F2120/W29F2120_actions";
import {
    Dropdown,
    Checkbox,
    Col,
    Row,
    Chip,
    TreeView,
    DateRangePicker,
    Typography,
    NumberInput,
    ButtonIcon,
} from "diginet-core-ui/components";
import { makeStyles } from "diginet-core-ui/theme";
import Config from "../../../../config";
import GridContainer from "../../../grid-container/grid-container";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import moment from "moment";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import Filter from "../../../filter/filter";
import { Loading } from "../../../common/loading/loading";
import History from "../../../libs/history";

const useStyles = makeStyles(theme => ({
    bgRowEdit: {
        background: theme.colors.warning,
    },
    verticalHeader: {
        padding: `${theme.spacing([15, 0, 15])}!important`,
        "& .dx-datagrid-text-content": {
            transform: "rotate(-90deg)",
            transformOrigin: "50% 50%",
        },
    },
    textPreLine: {
        "& .dx-datagrid-text-content": {
            whiteSpace: "pre-line !important",
        },
    },
    txtRed: {
        color: `${theme.colors.danger}`
    }
}));

const W29F2120 = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [dataOrgChart, setDataOrgChart] = useState([]);
    const [dataCboEmployees, setDataCboEmployees] = useState({ rows: [], total: 0 });
    const [dataCboProjects, setDataCboProjects] = useState({ rows: [], total: 0 });
    const [dataCboPeriod, setDataCboPeriod] = useState([]);
    const [dataLeaveType, setDataLeaveType] = useState([]);
    const [dataGrid, setDataGrid] = useState({ rows: [], total: 0 });
    const [dataColumns, setDataColumns] = useState([]);
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [loadingGrid, setLoadingGrid] = useState(false);
    const [isWeek, setIsWeek] = useState(true);
    const [rangeDate, setRangeDate] = useState([]);
    const [periodTime, setPeriodTime] = useState("");
    const [iPermission, setIPermission] = useState(0);

    const filterGrid = useRef({
        OrgChartID: "",
        ProjectID: "",
        EmployeeID: "",
        skip: 0,
        limit: 15,
    });
    const filterCboEmployees = useRef({
        skip: 0,
        limit: 50,
        strSearch: "",
    });

    const filterCboProjects = useRef({
        strSearch: "",
        skip: 0,
        limit: 50,
    });
    const dataDateByTrans = useRef({
        MinDate: null,
        MaxDate: null,
    });

    const isWeekChecked = useRef(false);
    const isChangedRangeDate = useRef(false);
    const refDataGrid = useRef(null);
    const listCheckEmployeeUpdated = useRef([]);
    const oldDataGrid = useRef([]);
    const listFieldRemove = useRef(["IsEdit", "IsUpdated", "QuantityLeave", "ReadOnly"]);
    const dataFieldEditing = useRef("");
    const loadPermission = () => {
        dispatch(
            GeneralActions.getPermission("W29F2120", isPer => {
                setIPermission(isPer);
            })
        );
    };

    const loadCboEmployees = isReset => {
        const param = {
            Type: "EmployeeID",
            FormID: "W29F2120",
            Language: Config.language || "84",
            skip: filterCboEmployees.current.skip,
            limit: filterCboEmployees.current.limit,
            search: filterCboEmployees.current.strSearch,
        };
        setLoadingCboEmployees(true);
        dispatch(
            GeneralActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup.show("ERROR", err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const loadCboPeriod = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
        };
        dispatch(
            GeneralActions.getCboPeriod(params, (errors, data) => {
                if (errors) {
                    Config.popup.show("ERROR", errors);
                    return false;
                }
                setPeriodTime(data?.[0]?.PeriodTime || "");
                setDataCboPeriod(data || []);
            })
        );
    };

    const loadCboProjects = isReset => {
        const param = {
            HostID: "",
            FormID: "W29F2120",
            Language: Config.language || "84",
            skip: filterCboProjects.current.skip,
            limit: filterCboProjects.current.limit,
            search: filterCboProjects.current.strSearch,
        };
        setLoadingProjects(true);
        dispatch(
            GeneralActions.getCboProjects(param, (error, data) => {
                setLoadingProjects(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (data) {
                    const rows = data && data.rows ? data.rows : data;
                    const total = data && data.total ? data.total : data.length;
                    setDataCboProjects({
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total,
                    });
                }
            })
        );
    };

    const loadDateByTrans = (TranMonth, TranYear) => {
        const _TranMonth = TranMonth || Config.profile.TranMonth || moment().format("M");
        const _TranYear = TranYear || Config.profile.TranYear || moment().format("YYYY");
        const params = {
            TranMonth: _TranMonth,
            TranYear: _TranYear,
        };
        dispatch(
            GeneralActions.getDateByTrans(params, (errors, data) => {
                if (errors) {
                    Config.popup.show("ERROR", errors);
                    return false;
                }
                let maxDate = data?.MaxDate || moment().endOf("week").format("YYYY-MM-DD");
                let minDate = data?.MinDate || moment().startOf("week").format("YYYY-MM-DD");
                if (isWeek) {
                    maxDate = moment(minDate).endOf("week").format("YYYY-MM-DD");
                }
                dataDateByTrans.current.MinDate = minDate;
                dataDateByTrans.current.MaxDate = data?.MaxDate || moment().endOf("week").format("YYYY-MM-DD");
                isChangedRangeDate.current = true;
                setRangeDate([minDate, maxDate]);
            })
        );
    };

    const loadDataOrgChart = () => {
        dispatch(
            GeneralActions.getOrgCharts({}, (err, data) => {
                if (err) {
                    Config.popup.show("ERROR", err);
                    return false;
                }
                if (data) {
                    setDataOrgChart(data);
                }
            })
        );
    };

    const loadLeaveType = () => {
        const params = {
            FormID: "W29F2120",
            Mode: 0,
        };
        dispatch(
            W29F2120Actions.loadLeaveType(params, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                setDataLeaveType(data || []);
            })
        );
    };

    const getFieldName = (field = "", attendanceDate) => {
        return `${field}_${Config.convertDate(attendanceDate, "", "YYYYMMDD")}`;
    };

    const loadTimeKeeping = () => {
        const params = {
            FormID: "W29F2120",
            Mode: 0,
            AttendanceDateFrom: rangeDate?.[0] || null,
            AttendanceDateTo: rangeDate?.[1] || null,
            OrgChartID: filterGrid.current.OrgChartID || "",
            ProjectID: filterGrid.current.ProjectID,
            EmployeeID: filterGrid.current.EmployeeID,
            skip: filterGrid.current.skip,
            limit: filterGrid.current.limit,
        };
        setLoadingGrid(true);
        dispatch(
            W29F2120Actions.loadTimeKeeping(params, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                setLoadingGrid(false);
                if (data?.rows?.length > 0) {
                    let sortDate = data.rows.sort((left, right) => {
                        return moment(left.AttendanceDate).diff(moment(right.AttendanceDate));
                    });

                    moment.locale("en");
                    const columns = [];
                    let _dataGrid = [];
                    for (let i = 0; i < sortDate.length; i++) {
                        // phần tạo data lưới
                        const infoEmployee = {
                            EmployeeName: sortDate[i].EmployeeName,
                            EmployeeID: sortDate[i].EmployeeID,
                            [getFieldName("AttendanceDate", sortDate[i].AttendanceDate)]: Config.convertDate(
                                sortDate[i].AttendanceDate,
                                "",
                                "YYYY-MM-DD"
                            ),
                            [getFieldName("NumLeave", sortDate[i].AttendanceDate)]: sortDate[i].NumLeave,
                            [getFieldName("DayShift", sortDate[i].AttendanceDate)]: sortDate[i].DayShift,
                            [getFieldName("NightShift", sortDate[i].AttendanceDate)]: sortDate[i].NightShift,
                            [getFieldName("FirstSolar", sortDate[i].AttendanceDate)]: sortDate[i].FirstSolar,
                            [getFieldName("RopeAccess", sortDate[i].AttendanceDate)]: sortDate[i].RopeAccess,
                            [getFieldName("TTMB", sortDate[i].AttendanceDate)]: sortDate[i].TTMB,
                            [getFieldName("TimeOn", sortDate[i].AttendanceDate)]: sortDate[i].TimeOn,
                            [getFieldName("TimeOff", sortDate[i].AttendanceDate)]: sortDate[i].TimeOff,
                            [getFieldName("IsEdit", sortDate[i].AttendanceDate)]: sortDate[i].IsEdit,
                            [getFieldName("ReadOnly", sortDate[i].AttendanceDate)]: sortDate[i].ReadOnly,
                            [getFieldName("QuantityLeave", sortDate[i].AttendanceDate)]: sortDate[i].QuantityLeave,
                            [getFieldName("IsUpdated", sortDate[i].AttendanceDate)]: false,
                            [getFieldName("TransID", sortDate[i].AttendanceDate)]: sortDate[i].TransID,
                        };
                        const idxEmployee = _dataGrid.findIndex(d => d.EmployeeID === sortDate[i]?.EmployeeID);
                        if (idxEmployee >= 0) {
                            _dataGrid[idxEmployee] = { ...infoEmployee, ..._dataGrid[idxEmployee] };
                        } else {
                            _dataGrid.push(infoEmployee);
                        }

                        // phần sử lý data cột
                        const idxCol = columns.findIndex(
                            d => d.AttendanceDate === Config.convertDate(sortDate[i].AttendanceDate, "", "YYYYMMDD")
                        );
                        if (idxCol < 0) {
                            columns.push({
                                AttendanceDate: Config.convertDate(sortDate[i].AttendanceDate, "", "YYYYMMDD"),
                                dayOfWeek: `${Config.convertDate(sortDate[i].AttendanceDate, "", "dddd")}`,
                                caption: `${Config.convertDate(sortDate[i].AttendanceDate, "", "dddd - DD/MM/YYYY")}`,
                                columns: [
                                    // {
                                    //     isRorateText: true,
                                    //     alignment: "center",
                                    //     caption: "Phép/Leave",
                                    //     width: 100,
                                    //     // height: 250,
                                    //     dataField: getFieldName("NumLeave", sortDate[i].AttendanceDate),
                                    //     AttendanceDate: moment(sortDate[i].AttendanceDate).format("YYYYMMDD"),
                                    // },
                                    {
                                        isRorateText: true,
                                        alignment: "center",
                                        caption: "Phép/Leave",
                                        width: 100,
                                        isCellDecimal: true,
                                        dataField: getFieldName("QuantityLeave", sortDate[i].AttendanceDate),
                                        AttendanceDate: Config.convertDate(sortDate[i].AttendanceDate, "", "YYYYMMDD"),
                                    },
                                    {
                                        isRorateText: true,
                                        caption: "Day shift",
                                        alignment: "center",
                                        width: 100,
                                        isCellDecimal: true,
                                        dataField: getFieldName("DayShift", sortDate[i].AttendanceDate),
                                        AttendanceDate: Config.convertDate(sortDate[i].AttendanceDate, "", "YYYYMMDD"),
                                    },
                                    {
                                        isRorateText: true,
                                        caption: "Night shift",
                                        alignment: "center",
                                        width: 100,
                                        isCellDecimal: true,
                                        dataField: getFieldName("NightShift", sortDate[i].AttendanceDate),
                                        AttendanceDate: Config.convertDate(sortDate[i].AttendanceDate, "", "YYYYMMDD"),
                                    },
                                    {
                                        isRorateText: true,
                                        caption: "First Solar (25%)",
                                        alignment: "center",
                                        width: 100,
                                        isCellDecimal: true,
                                        dataField: getFieldName("FirstSolar", sortDate[i].AttendanceDate),
                                        AttendanceDate: Config.convertDate(sortDate[i].AttendanceDate, "", "YYYYMMDD"),
                                    },
                                    {
                                        isRorateText: true,
                                        caption: "Rope Access (50%)",
                                        alignment: "center",
                                        width: 100,
                                        isCellDecimal: true,
                                        dataField: getFieldName("RopeAccess", sortDate[i].AttendanceDate),
                                        AttendanceDate: Config.convertDate(sortDate[i].AttendanceDate, "", "YYYYMMDD"),
                                    },
                                    {
                                        isRorateText: true,
                                        isPreLineText: true,
                                        caption: "TSA/ TSZ/Met mast/ Blade repair(30%)",
                                        alignment: "center",
                                        width: 130,
                                        isCellDecimal: true,
                                        dataField: getFieldName("TTMB", sortDate[i].AttendanceDate),
                                        AttendanceDate: Config.convertDate(sortDate[i].AttendanceDate, "", "YYYYMMDD"),
                                    },
                                    {
                                        caption: "Working time",
                                        alignment: "center",
                                        isCellTime: true,
                                        columns: [
                                            {
                                                caption: "IN",
                                                alignment: "center",
                                                width: 100,
                                                dataField: getFieldName("TimeOn", sortDate[i].AttendanceDate),
                                                AttendanceDate: Config.convertDate(
                                                    sortDate[i].AttendanceDate,
                                                    "",
                                                    "YYYYMMDD"
                                                ),
                                            },
                                            {
                                                caption: "OUT",
                                                alignment: "center",
                                                width: 100,
                                                dataField: getFieldName("TimeOff", sortDate[i].AttendanceDate),
                                                AttendanceDate: Config.convertDate(
                                                    sortDate[i].AttendanceDate,
                                                    "",
                                                    "YYYYMMDD"
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        caption: "IsEdit",
                                        dataField: getFieldName("IsEdit", sortDate[i].AttendanceDate),
                                        visible: false,
                                    },
                                    {
                                        caption: "ReadOnly",
                                        dataField: getFieldName("ReadOnly", sortDate[i].AttendanceDate),
                                        visible: false,
                                    },
                                    {
                                        dataField: getFieldName("TransID", sortDate[i].AttendanceDate),
                                        visible: false,
                                    },
                                ],
                            });
                        }
                    }

                    oldDataGrid.current = JSON.parse(JSON.stringify(_dataGrid));
                    onReset();
                    setDataColumns(columns);
                    setDataGrid({ rows: _dataGrid, total: data.total });
                }
            })
        );
    };

    const getListEmployee = dataSource => {
        let dataEmployee = [];
        for (let i = 0; i < listCheckEmployeeUpdated.current.length; i++) {
            const _EmployeeID = listCheckEmployeeUpdated.current[i].EmployeeID;
            let employeeUpdated = dataSource.find(d => d.EmployeeID === _EmployeeID) || {};
            for (let [key, value] of Object.entries(employeeUpdated)) {
                // lấy ra các trường theo time AttendanceDate của từng cell
                if (key.includes(listCheckEmployeeUpdated.current[i].attendanceDateCell)) {
                    const keyRemoveTime = key.split("_")?.[0] || "";
                    if (listFieldRemove.current.includes(keyRemoveTime)) continue;
                    dataEmployee[i] = { ...dataEmployee[i], EmployeeID: _EmployeeID, [keyRemoveTime]: value };
                }
            }
        }
        return dataEmployee;
    };

    const onSave = () => {
        if (refDataGrid.current) {
            refDataGrid.current.instance.saveEditData();
            const dataSource = refDataGrid.current.instance.option("dataSource");
            const dataUpdated = getListEmployee(dataSource);

            const params = {
                FormID: "W29F2120",
                Mode: 0,
                data: JSON.stringify(dataUpdated),
            };
            dispatch(
                W29F2120Actions.save(params, async (error, data) => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    }
                    if (data) {
                        if (data.Status === 0) {
                            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                            await saveHistory(dataUpdated);
                            loadTimeKeeping();
                        }
                    }
                })
            );
        }
    };

    const saveHistory = async dt => {
        if (dt.length > 0) {
            const _oldDataGrid = getListEmployee(oldDataGrid.current);
            //name Content
            const captions = {
                AttendanceDate: "Ngay_cong",
                Employee: "Nhan_vien",
                NumLeave: "Phep",
                DayShift: "DayShift",
                NightShift: "NightShift",
                FirstSolar: "FirstSolar ",
                TTMB: "TTMB",
                TimeOn: "TimeOn",
                TimeOff: "TimeOff",
            };

            const historyMaster = new History({}); //Init history

            dt.map(d => {
                const _dataOldEmployee = _oldDataGrid.find(f => f.EmployeeID === d.EmployeeID) || {};
                const options = {
                    data: d,
                    dataCompare: _dataOldEmployee,
                    captions: captions,
                    action: 1,
                    ModuleID: "D29",
                    TransID: d.TransID,
                    TransactionID: "W29F2120",
                    TransactionName: Config.lang("Cham_cong"),
                    itemRender: (e, type) => {
                        const { key, data, oldData } = e;
                        if (type === "Content") {
                            if (key === "Employee") {
                                const employee = oldDataGrid.current.find(f => f.EmployeeID === data.EmployeeID);
                                data.Employee = `${employee.EmployeeID} - ${employee.EmployeeName}`;
                                oldData.Employee = `${employee.EmployeeID} - ${employee.EmployeeName}`;
                            }
                        }
                    },
                };
                const _h = new History(options);
                historyMaster.import(_h.get());
                return d;
            });

            if (historyMaster.get()?.length > 0) {
                await historyMaster.save();
                onReset();
            }
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };
    const onReset = () => {
        listCheckEmployeeUpdated.current = [];
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iPermission) return;
        loadCboEmployees();
        loadCboProjects();
        loadDataOrgChart();
        loadLeaveType();
        loadCboPeriod();
        loadDateByTrans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    useEffect(() => {
        if (isWeekChecked.current) {
            if (isWeek) {
                const maxDate = moment(dataDateByTrans.current.MinDate).endOf("week").format("YYYY-MM-DD");
                setRangeDate([dataDateByTrans.current.MinDate, maxDate]);
            } else {
                setRangeDate([dataDateByTrans.current.MinDate, dataDateByTrans.current.MaxDate]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWeek]);

    useEffect(() => {
        if (isChangedRangeDate.current) {
            isChangedRangeDate.current = false;
            loadTimeKeeping();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeDate]);

    const handleFilterChange = (key, e) => {
        const value = e?.value ?? e?.target?.value ?? "";
        switch (key) {
            case "OrgChartID":
                filterGrid.current.OrgChartID = value;
                break;
            case "ProjectID":
                filterGrid.current.ProjectID = value;
                break;
            case "EmployeeID":
                filterGrid.current.EmployeeID = value;
                break;
            default:
                break;
        }
    };

    const renderFilter = () => {
        return (
            <Filter
                isUseDDCore
                placeholder={Config.lang("Tim_kiem")}
                renderFilter={() => (
                    <>
                        <Row>
                            <Col xs={12}>
                                <Dropdown
                                    multiple
                                    label={Config.lang("Co_cau_to_chuc")}
                                    clearAble
                                    dataSource={dataOrgChart}
                                    valueExpr={"OrgChartID"}
                                    displayExpr={"OrgName"}
                                    keyExpr={"OrgName"}
                                    onChange={e => handleFilterChange("OrgChartID", e)}
                                    defaultValue={filterGrid.current.OrgChartID}
                                    viewType={"outlined"}
                                >
                                    <TreeView
                                        multiple
                                        allowSearch
                                        dataSource={dataOrgChart}
                                        displayExpr={"OrgName"}
                                        id={"OrgChartID"}
                                        onChange={e => handleFilterChange("OrgChartID", e)}
                                        parentID={"OrgChartParentID"}
                                        defaultValue={filterGrid.current.OrgChartID}
                                        valueExpr={"OrgChartID"}
                                    />
                                </Dropdown>
                            </Col>
                            <Col xs={12}>
                                <Dropdown
                                    clearAble
                                    allowSearch
                                    dataSource={dataCboProjects.rows}
                                    total={dataCboProjects.total}
                                    skip={filterCboProjects.current.skip}
                                    limit={filterCboProjects.current.limit}
                                    displayExpr={"{ProjectID} - {ProjectName}"}
                                    valueExpr={"ProjectID"}
                                    viewType={"outlined"}
                                    defaultValue={filterGrid.current.ProjectID}
                                    loading={loadingProjects}
                                    label={Config.lang("Du_an")}
                                    onChange={e => handleFilterChange("ProjectID", e)}
                                    onInput={e => {
                                        filterCboProjects.current.strSearch = e.target.value;
                                        filterCboProjects.current.skip = 0;
                                        loadCboProjects(true);
                                    }}
                                    onLoadMore={e => {
                                        filterCboProjects.current.skip = e.skip;
                                        filterCboProjects.current.limit = e.limit;
                                        loadCboProjects();
                                    }}
                                />
                            </Col>
                            <Col xs={12}>
                                <Dropdown
                                    allowSearch
                                    clearAble
                                    dataSource={dataCboEmployees.rows}
                                    total={dataCboEmployees.total}
                                    skip={filterCboEmployees.current.skip}
                                    limit={filterCboEmployees.current.limit}
                                    displayExpr={"{EmployeeID} - {EmployeeName}"}
                                    valueExpr={"EmployeeID"}
                                    placeholder={Config.lang("Chon")}
                                    label={Config.lang("Nhan_vien")}
                                    onChange={e => handleFilterChange("EmployeeID", e)}
                                    defaultValue={filterGrid.current.EmployeeID}
                                    viewType={"outlined"}
                                    loading={loadingCboEmployees}
                                    onInput={e => {
                                        filterCboEmployees.current.strSearch = e?.target?.value ?? "";
                                        filterCboEmployees.current.skip = 0;
                                        loadCboEmployees(true);
                                    }}
                                    onLoadMore={e => {
                                        filterCboEmployees.current.skip = e?.skip ?? 0;
                                        filterCboEmployees.current.limit = e?.limit ?? 10;
                                        loadCboEmployees();
                                    }}
                                />
                            </Col>
                        </Row>
                        <div className={"display_row align-center valign-middle mgt4x"}>
                            <ButtonGeneral
                                name={Config.lang("Tim_kiem")}
                                typeButton={"search"}
                                size={"large"}
                                color={"primary"}
                                variant={"outlined"}
                                onClick={loadTimeKeeping}
                            />
                        </div>
                    </>
                )}
            />
        );
    };

    const handleHeaderFilter = (key, e) => {
        const value = e?.value ?? e?.target?.value ?? "";
        switch (key) {
            case "RangeDateHeader":
                const MinDate = Array.isArray(value) ? value[0] : value;
                const MaxDate = Array.isArray(value) ? value[1] : value;
                setRangeDate([MinDate, MaxDate]);
                break;
            case "PeriodTimeHeader":
                loadDateByTrans(e.data.TranMonth, e.data.TranYear);
                setPeriodTime(value);
                break;
            case "PrevWeek":
                if (isWeek) {
                    const dateFrom = moment(rangeDate[0]).subtract(1, "week").format("YYYY-MM-DD");
                    const dateTo = moment(dateFrom).endOf("week").format("YYYY-MM-DD");
                    const _dataForm =
                        dateFrom < dataDateByTrans.current?.MinDate ? dataDateByTrans.current?.MinDate : dateFrom;
                    setRangeDate([_dataForm, dateTo]);
                } else {
                    setRangeDate([dataDateByTrans.current.MinDate, rangeDate[1]]);
                }
                break;
            case "NextWeek":
                if (isWeek) {
                    const dateFrom = moment(rangeDate[0]).add(1, "week").startOf("week").format("YYYY-MM-DD");
                    const dateTo = moment(dateFrom).endOf("week").format("YYYY-MM-DD");
                    const _dataTo =
                        dateTo > dataDateByTrans.current?.MaxDate ? dataDateByTrans.current?.MaxDate : dateTo;
                    setRangeDate([dateFrom, _dataTo]);
                } else {
                    setRangeDate([rangeDate[1], dataDateByTrans.current.MaxDate]);
                }
                break;
            default:
                break;
        }
    };

    const onChangePage = page => {
        filterGrid.current.skip = page * filterGrid.current.limit;
        loadTimeKeeping();
    };

    const onChangePerPage = perPage => {
        filterGrid.current.skip = 0;
        filterGrid.current.limit = perPage;
        loadTimeKeeping();
    };

    const cellRenderNumLeave = (evt, dataColumn) => {
        if (!evt) return null;
        const { data } = evt.row || {};
        const cellElement = evt.component.getCellElement(evt.rowIndex, evt.column.dataField);
        if (cellElement && data[`IsEdit_${dataColumn.AttendanceDate}`] === 1) {
            cellElement.classList.add(...getClassName(classes.bgRowEdit));
        }
        const listNumLeave = data[`NumLeave_${dataColumn.AttendanceDate}`]?.split(";") || [];
        return <Typography>{listNumLeave?.length > 0 ? listNumLeave.join("\n") : ""}</Typography>;
    };

    const headerCellRenderNumLeave = evt => {
        if (!evt) return null;
        return (
            <Typography style={{ transform: "rotate(-90deg)", transformOrigin: "50% 50%" }} type={"h5"}>
                {evt.column.caption}
            </Typography>
        );
    };

    const getClassName = (className = "") => {
        const index = className.indexOf(" ");
        const className1 = className.substring(0, index);
        const className2 = className.substring(index + 1);
        return [className1, className2];
    };

    const onEditingStart = e => {
        if (!e) return false;
        dataFieldEditing.current = e.column.dataField;
        if (e.column.AttendanceDate) {
            const fieldReadOnly = `ReadOnly_${e.column.AttendanceDate}`;
            if (e.data[fieldReadOnly] === 1) {
                e.cancel = true;
            }
        }
    };

    const onCellPrepared = e => {
        if (!e) return false;
        if (e.column.AttendanceDate && e.rowType === "data") {
            const fieldIsEdit = `IsEdit_${e.column.AttendanceDate}`;
            if (e.rowType === "data" && e.data[fieldIsEdit] === 1) {
                e.cellElement.classList.add(...getClassName(classes.bgRowEdit));
            }
        }
        if (e.rowType === "header" && e.column.isRorateText) {
            if (e?.column?.isPreLineText) {
                e.cellElement.classList.add(
                    ...getClassName(classes.textPreLine),
                    ...getClassName(classes.verticalHeader)
                );
            } else {
                e.cellElement.classList.add(...getClassName(classes.verticalHeader));
            }
        }
    };

    const customizeText = data => {
        return new Intl.NumberFormat().format(data.value);
    };

    const onFocusOut = e => {
        if (!e) return null;
        let { value } = e.event.target;
        if (value === "__:__") {
            value = ``;
        }
        if (value && parseInt(value.split(":")[0]) > 24) {
            value = `24:00`;
        }
        e.component.option("value", value);
    };

    const onInput = e => {
        if (!e) return null;
        let { value } = e.event.target;
        e.component.option("value", value);
        if (value.split("_")[0] === "") {
            value = value.replace(value, "");
        }

        if (value.split("_").length <= 3) {
            value = value.replace(/_/g, "0");
        }

        if (value.split("_").length === 4 && value[0] !== "_") {
            const firstLetter = parseInt(value[0]);
            value = (firstLetter >= 3 ? `0${firstLetter}` : `${firstLetter}0`) + `:00`;
        }
        e.component.option("value", value);
    };

    const onRowUpdating = e => {
        const { newData, oldData } = e;
        if (Object.keys(newData).length > 0) {
            // attendanceDateCell là giá trị AttendanceDate của từng cell trong group column theo AttendanceDate
            const attendanceDateCell = Object.keys(newData)[0].split("_")[1];
            const fieldFirstSolar = `FirstSolar_${attendanceDateCell}`;
            const fieldRopeAccess = `RopeAccess_${attendanceDateCell}`;
            const fieldTTMB = `TTMB_${attendanceDateCell}`;
            if(newData.hasOwnProperty(fieldFirstSolar) && dataFieldEditing.current === fieldFirstSolar) {
                newData[fieldRopeAccess] = 0;
                newData[fieldTTMB] = 0;
            }
            if(newData.hasOwnProperty(fieldRopeAccess) && dataFieldEditing.current === fieldRopeAccess) {
                newData[fieldFirstSolar] = 0;
                newData[fieldTTMB] = 0;
            }
            if(newData.hasOwnProperty(fieldTTMB) && dataFieldEditing.current === fieldTTMB) {
                newData[fieldFirstSolar] = 0;
                newData[fieldRopeAccess] = 0;
            }
            newData[`IsUpdated_${attendanceDateCell}`] = true;
            const idxEmployeeExist = listCheckEmployeeUpdated.current.findIndex(
                d => d.EmployeeID === oldData.EmployeeID && attendanceDateCell === d.attendanceDateCell
            );
            if (idxEmployeeExist < 0) {
                listCheckEmployeeUpdated.current.push({
                    EmployeeID: oldData.EmployeeID,
                    [`IsUpdated_${attendanceDateCell}`]: true,
                    attendanceDateCell: attendanceDateCell,
                });
            }
        }
    };

    const gridEmployee = useMemo(() => {
        return (
            <GridContainer
                reference={ref => (refDataGrid.current = ref)}
                style={{ border: "none", overflowX: "scroll" }}
                dataSource={dataGrid.rows}
                totalItems={dataGrid.total}
                keyExpr={"EmployeeID"}
                showRowLines={false}
                showBorders={false}
                showColumnLines={true}
                rowAlternationEnabled={true}
                noDataText={Config.lang("No_data")}
                hoverStateEnabled={true}
                typePaging={"remote"}
                itemPerPage={filterGrid.current.limit}
                skipPerPage={filterGrid.current.skip}
                height={Config.getHeightGrid() - 120}
                pagerFullScreen={false}
                editing={{
                    mode: "cell",
                    refreshMode: "reshape",
                    allowUpdating: true,
                    startEditAction: "click",
                }}
                onRowUpdating={onRowUpdating}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                onEditingStart={onEditingStart}
                onCellPrepared={onCellPrepared}
                onRowUpdated={() => {
                    dataFieldEditing.current = "";
                }}
            >
                <Column
                    caption={Config.lang("Nhan_vien")}
                    allowEditing={false}
                    dataField={"EmployeeName"}
                    width={300}
                    fixed={true}
                />
                {dataColumns.map((item, idx) => (
                    <Column key={idx} cssClass={item.dayOfWeek === "Sunday" ? classes.txtRed : ""} alignment={"center"} caption={item.caption}>
                        {item.hasOwnProperty("columns") &&
                            item.columns.map((col, idxC) => {
                                // trường NumLeave là string không thể tính tổng được nên dùng trường QuantityLeave thay thế.
                                if (col.dataField?.includes("QuantityLeave_")) {
                                    return (
                                        <Column
                                            key={idxC}
                                            width={120}
                                            allowEditing={false}
                                            alignment={"center"}
                                            caption={col.caption}
                                            dataField={col.dataField}
                                            cellRender={evt => cellRenderNumLeave(evt, col)}
                                            headerCellRender={headerCellRenderNumLeave}
                                        />
                                    );
                                }
                                if (col.isCellTime) {
                                    return (
                                        <Column key={idxC} caption={col.caption} alignment={"center"}>
                                            {col.hasOwnProperty("columns") &&
                                                col.columns.map((colTime, idxT) => {
                                                    return (
                                                        <Column
                                                            key={idxT}
                                                            AttendanceDate={colTime.AttendanceDate}
                                                            width={colTime.width}
                                                            alignment={colTime.alignment}
                                                            caption={colTime.caption}
                                                            dataField={colTime.dataField}
                                                            editorOptions={{
                                                                mask: "AB:CD",
                                                                maskRules: {
                                                                    A: /[0-9_]/,
                                                                    B: /[0-9_]/,
                                                                    C: /[0-5_]/,
                                                                    D: /[0-9_]/,
                                                                },
                                                                onFocusOut: e => onFocusOut(e),
                                                                onInput: e => onInput(e),
                                                            }}
                                                        />
                                                    );
                                                })}
                                        </Column>
                                    );
                                }
                                if (col.isCellDecimal) {
                                    return (
                                        <Column
                                            key={idxC}
                                            editCellRender={e => {
                                                return (
                                                    <NumberInput
                                                        disabledNegative={true}
                                                        decimalDigit={4}
                                                        decimalSymbol="."
                                                        thousandSeparator
                                                        allowZero
                                                        value={e.value}
                                                        onChange={vl => e.setValue(vl.value)}
                                                    />
                                                );
                                            }}
                                            {...col}
                                        />
                                    );
                                }
                                return <Column key={idxC} alignment={"center"} {...col} />;
                            })}
                    </Column>
                ))}
                <Column dataField={"EmployeeID"} visible={false} />

                <Summary>
                    <TotalItem column="EmployeeName" displayFormat={"TOTAL/TỔNG"} />
                    {dataColumns.map(item => {
                        return item.columns.map((col, index) => {
                            return (
                                <TotalItem
                                    key={index}
                                    column={col.dataField}
                                    summaryType="sum"
                                    customizeText={customizeText}
                                    valueFormat={"decimal"}
                                    displayFormat={"{0}"}
                                />
                            );
                        });
                    })}
                </Summary>
            </GridContainer>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid.rows, dataColumns]);

    return (
        <>
            <Loading visible={loadingGrid} />
            <ActionToolbar title={Config.lang("Cham_cong")} alignment={"space-between"}>
                <div className={"display_row align-center"}>
                    <ButtonGeneral
                        name={Config.lang("Luu")}
                        typeButton={"Save"}
                        size="large"
                        color="info"
                        viewType={"filled"}
                        disabled={iPermission <= 3}
                        onClick={() => onSave()}
                    />
                </div>
                <div className={"display_row align-center valign-middle"}>
                    <Checkbox
                        checked={isWeek}
                        label={Config.lang("Hien_thi_theo_tuan")}
                        color={"primary"}
                        onChange={e => {
                            if (!isWeekChecked.current) {
                                isWeekChecked.current = true;
                            }
                            setIsWeek(e.target.checked);
                        }}
                    />
                    {isWeek && (
                        <Chip
                            className={"mgl4x"}
                            label={`${Config.lang("Tuan_thu")} ${
                                Config.convertDate(rangeDate?.[0], "", "W") || 0
                            }`}
                        />
                    )}
                    <div className={"display_row align-center valign-middle"}>
                        <ButtonIcon
                            viewType={"text"}
                            disabled={rangeDate[0] <= dataDateByTrans.current.MinDate}
                            onClick={() => {
                                if (!isChangedRangeDate.current) {
                                    isChangedRangeDate.current = true;
                                }
                                handleHeaderFilter("PrevWeek");
                            }}
                            name={"ArrowDoubleLeft"}
                        />
                        <DateRangePicker
                            className={"mg0"}
                            controls
                            min={isWeek ? rangeDate[0] : dataDateByTrans.current.MinDate}
                            max={isWeek ? rangeDate[1] : dataDateByTrans.current.MaxDate}
                            showUnitCount={false}
                            value={rangeDate}
                            onChange={e => {
                                if (!isChangedRangeDate.current) {
                                    isChangedRangeDate.current = true;
                                }
                                handleHeaderFilter("RangeDateHeader", e);
                            }}
                            returnFormat={"YYYY-MM-DD"}
                        />
                        <ButtonIcon
                            viewType={"text"}
                            disabled={rangeDate[1] >= dataDateByTrans.current.MaxDate}
                            onClick={() => {
                                if (!isChangedRangeDate.current) {
                                    isChangedRangeDate.current = true;
                                }
                                handleHeaderFilter("NextWeek");
                            }}
                            name={"ArrowDoubleRight"}
                        />
                    </div>
                    <Dropdown
                        className={"mg0"}
                        style={{ width: 120 }}
                        dataSource={dataCboPeriod}
                        valueExpr={"PeriodTime"}
                        displayExpr={"PeriodTime"}
                        value={periodTime}
                        onChange={e => handleHeaderFilter("PeriodTimeHeader", e)}
                    />
                </div>
            </ActionToolbar>
            <div className={"hidden"}>{renderFilter()}</div>
            <div style={{ height: 60, overflow: "auto" }} className={"mgt4x mgb6x"}>
                <Row>
                    {dataLeaveType.map((d, idx) => {
                        return (
                            <Col key={idx} xs={4}>
                                <Typography type={"h3"} color={d?.ColorLeave ?? ""}>
                                    {d?.LeaveName ?? ""}
                                </Typography>
                            </Col>
                        );
                    })}
                </Row>
            </div>

            {gridEmployee}
        </>
    );
};

export default W29F2120;
