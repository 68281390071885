/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/13/2022
 * @Example
 */

import {
    Button,
    DateRangePicker,
    Dropdown,
    Typography,
    Row,
    Col,
    Avatar,
    Chip,
    TextInput,
} from "diginet-core-ui/components";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../config";
import * as approvalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import Approvals from "../../../approvals/approvals";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Filter from "../../../filter/filter";
import { makeStyles } from "diginet-core-ui/theme";
import W29F2309 from "../W29F2309/W29F2309";

const useStyles = makeStyles(theme => ({
    height: {
        height: 48,
    },
    cellInfo: {
        backgroundColor: Config.hexToRGBA(theme.colors.info, 0.1),
    },
    cellSuccess: {
        backgroundColor: Config.hexToRGBA(theme.colors.success, 0.1),
    },
    cellWarning: {
        backgroundColor: Config.hexToRGBA(theme.colors.warning, 0.1),
    },
    inputTime: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.dark6}`,
        borderRadius: 4,
    },
    borderBottomDark2: {
        borderBottom: `1px solid ${theme.colors.dark2}`,
    },
    dateInfo: {
        [theme.breakpoints.down("xl")]: {
            marginLeft: `${theme.spacing(20)}px`,
        },
    },
    borderBottomDark: {
        borderBottom: `1px solid ${theme.colors.dark}`
    },
    marginTime: {
        marginRight: `${theme.spacing(8)}px`,
        [theme.breakpoints.down("xl")]: {
            marginRight: `${theme.spacing(4)}px`,
        },
    }
}));

const FormID = "W29F2308";
const Language = Config.language || "84";

const W29F2308 = props => {
    const classes = useStyles();

    const { location } = props;
    const dispatch = useDispatch();
    const dataCboAppStatus = useSelector(state => state?.general?.getCboAppStatus ?? []);

    const [iPermission, setIPermission] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [openW29F2309, setOpenW29F2309] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0,
    });

    const [dataFilter, setDataFilter] = useState(() => ({
        EmployeeID: "",
        ApprovalStatus: null,
        DateFrom: null,
        DateTo: null,
    }));

    const [filter, setFilter] = useState(() => ({
        EmployeeID: "",
        ApprovalStatus: null,
        DateFrom: null,
        DateTo: null,
    }));

    const approvalRef = useRef(null);

    const paramFilterCboEmployees = {
        skip: 0,
        limit: 50,
        search: "",
    };

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission("W29F2308", isPer => {
                setIPermission(isPer);
            })
        );
    };

    const loadCboAppStatus = () => {
        const param = {
            FormID: FormID,
            Language: Language,
        };

        dispatch(
            generalActions.getCboAppStatus(param, error => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
            })
        );
    };

    const loadCboEmployees = isReset => {
        const param = {
            Type: "EmployeeID",
            FormID: FormID,
            Language: Language,
            ...paramFilterCboEmployees,
        };
        setLoadingCboEmployees(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup.show("ERROR", err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const loadFormInfo = (voucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID() ?? "",
            FormID: FormID,
            Language: Language,
            VoucherID: voucherID ?? "",
        };
        setLoading(true);
        dispatch(
            approvalActions.getFormInfo(params, (err, dt) => {
                setLoading(false);
                if (err) {
                    return Config.popup.show("ERROR", err);
                }
                if (dt) {
                    setDataForm(dt);
                    cb && cb(dt);
                }
            })
        );
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const VoucherID = location?.state?.voucher_id ?? null;
        if (VoucherID) {
            loadFormInfo(VoucherID, dt => {
                dt.VoucherID = VoucherID;
                approvalRef.current && approvalRef.current.showPopup(dt);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const onLoadCboFilter = () => {
        loadCboAppStatus();
        loadCboEmployees();
    };

    const renderFilter = () => {
        const { EmployeeID, ApprovalStatus, DateFrom, DateTo } = filter;
        return (
            <Filter
                isUseDDCore
                placeholder={Config.lang("Tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                renderFilter={() => {
                    return (
                        <>
                            <DateRangePicker
                                clearAble
                                controls
                                value={[DateFrom, DateTo]}
                                label={Config.lang("Ngay_lam_viec")}
                                onChange={e => filterChange("Date", e)}
                                placeholder={"dd/mm/yyyy - dd/mm/yyyy"}
                                returnFormat={"YYYY-MM-DD"}
                                viewType={"outlined"}
                            />
                            <Dropdown
                                clearAble
                                valueExpr={"AppStatusID"}
                                displayExpr={"AppStatusName"}
                                placeholder={Config.lang("Chon")}
                                label={Config.lang("Trang_thai")}
                                onChange={e => filterChange("ApprovalStatus", e)}
                                dataSource={dataCboAppStatus}
                                value={ApprovalStatus}
                                viewType={"outlined"}
                            />
                            <Dropdown
                                allowSearch
                                clearAble
                                dataSource={dataCboEmployees.rows}
                                total={dataCboEmployees.total}
                                skip={paramFilterCboEmployees.skip}
                                limit={paramFilterCboEmployees.limit}
                                displayExpr={"{EmployeeID} - {EmployeeName}"}
                                valueExpr={"EmployeeID"}
                                placeholder={Config.lang("Chon")}
                                label={Config.lang("Nhan_vien")}
                                onChange={e => filterChange("EmployeeID", e)}
                                value={EmployeeID}
                                viewType={"outlined"}
                                loading={loadingCboEmployees}
                                onInput={e => {
                                    paramFilterCboEmployees.search = e?.target?.value ?? "";
                                    paramFilterCboEmployees.skip = 0;
                                    loadCboEmployees(true);
                                }}
                                onLoadMore={e => {
                                    paramFilterCboEmployees.skip = e?.skip ?? 0;
                                    paramFilterCboEmployees.limit = e?.limit ?? 10;
                                    loadCboEmployees();
                                }}
                            />
                            <div className={"display_row valign-middle"}>
                                <Button
                                    text={Config.lang("Tim_kiem")}
                                    viewType={"outlined"}
                                    color={"primary"}
                                    onClick={onSearch}
                                    startIcon={"Search"}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const onSearch = () => {
        setDataFilter({ ...dataFilter, ...filter });
    };

    const filterChange = (key, data) => {
        switch (key) {
            case "ApprovalStatus":
                setFilter({
                    ...filter,
                    [key]: data?.value ?? null,
                });
                break;
            case "EmployeeID":
                setFilter({
                    ...filter,
                    [key]: data?.value ?? "",
                });
                break;
            case "Date":
                setFilter({
                    ...filter,
                    DateFrom: data?.value?.[0] ?? null,
                    DateTo: data?.value?.[1] ?? null,
                });
                break;
            default:
                break;
        }
    };

    const onSelectionChanged = e => {
        if (!e) return false;
        loadFormInfo(e.VoucherID);
    };

    const insertColon = time => {
        if (!time) return "";
        if (time.includes(":")) return time;
        return `${time.slice(0, 2)}:${time.slice(2)}`;
    };

    const onOpenW29F2039 = isLoad => {
        setOpenW29F2309(!openW29F2309);
        if (isLoad && approvalRef.current) approvalRef.current.loadGrid();
    };

    const cellRenderSuccess = ({ OTFrom, OTTo, OTHours }) => {
        return (
            <div className={`${classes.cellSuccess} ${classes.height} display_row align-center valign-middle`}>
                {OTFrom && OTTo &&
                    <Typography
                        className={classes.marginTime}>
                        {`${insertColon(OTFrom)} - ${insertColon(OTTo)}`}
                    </Typography>}
                <Typography>{`${OTHours || 0}h`}</Typography>
            </div>
        );
    };

    const cellRenderWarning = ({ OTFrom, OTTo, OTHours }) => {
        return (
            <div className={`${classes.cellWarning} ${classes.height} display_row align-center valign-middle`}>
                <div className={`display_row align-center mgr4x`}>
                    {OTFrom !== "" ? (
                        <>
                            <Typography className={classes.inputTime}>{insertColon(OTFrom)}</Typography>
                            <Typography className={"mgl1x mgr1x"}>-</Typography>
                        </>
                    ) : (
                        ""
                    )}
                    {OTTo !== "" ? <Typography className={classes.inputTime}>{insertColon(OTTo)}</Typography> : ""}
                </div>
                <Typography>{`${OTHours || 0}h`}</Typography>
            </div>
        );
    };

    const cellRenderText = (text = "", classBorder= "") => {
        return (
            <Col xs={12} className={classBorder}>
                <div className={`${classes.height} display_row align-center valign-middle`}>
                    <Typography type={"h3"}>
                        {text}
                    </Typography>
                </div>
            </Col>
        )
    };

    const cellRenderInfo = (time = "", classBorder= "", key) => {
        return (
            <Col key={key} xs={12} className={classBorder}>
                <div className={`${classes.cellInfo} ${classes.height} display_row align-center valign-middle`}>
                    <Typography>{`${time || 0}h`}</Typography>
                </div>
            </Col>
        )
    };



    const renderFormInfo = data => {
        const user = Config.getUser({ EmployeeID: data?.EmployeeID || "" });
        const arrIcons = ["OneSquare", "TwoSquare", "ThreeSquare", "FourSquare", "FiveSquare"];
        let arrChips = [];
        for (let i = 1; i <= 5; i++) {
            if (data[`TimeOn${i}`] !== "" || data[`TimeOff${i}`] !== "") {
                arrChips.push({
                    icon: arrIcons[i - 1],
                    indexTime: i
                });
            }
        }
        return (
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                    <div className={"display_row"}>
                        <Avatar
                            width={64}
                            height={64}
                            className={"mgr4x"}
                            readOnly
                            data={Config.getInfoAvatar(user)}
                            src={Config.getUserPicture(user.UserPictureURL)}
                        />
                        <div>
                            <div className={"display_row"}>
                                <Typography className={"mgr1x"} type={"h3"}>
                                    {Config.lang("Nhan_vien")}:
                                </Typography>
                                <Typography>{`${user?.EmployeeName ?? ""}`}</Typography>
                            </div>
                            <div className={"display_row"}>
                                <Typography className={"mgr1x"} type={"h3"}>
                                    {Config.lang("Phong_ban")}:
                                </Typography>
                                <Typography>{`${user?.DepartmentID ?? ""} - ${user?.DepartmentName ?? ""}`}</Typography>
                            </div>
                            <div className={"display_row"}>
                                <Typography className={"mgr1x"} type={"h3"}>
                                    {Config.lang("Ngay_vao_lam")}:
                                </Typography>
                                <Typography>{Config.helpers.convertDate(user?.DateJoined ?? "", "") ?? ""}</Typography>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                    <div className={classes.dateInfo}>
                        <Typography className={"mgr1x"} type={"h3"}>
                            {`${data?.WorkingDay ?? ""} - ${data?.WorkDayType ?? ""}`}
                        </Typography>
                        <Typography>{data?.ShiftName ?? ""}</Typography>
                        {(data?.SPreOTFrom !== "" || data?.SPreOTTo !== "") ? (
                            <Typography>
                                {Config.lang("TCT")}: {`${insertColon(data?.SPreOTFrom)} - ${insertColon(data?.SPreOTTo)}`}
                            </Typography>
                        ) : (
                            ""
                        )}

                        {(data?.SBetOTFrom !== "" || data?.SBetOTTo !== "") ? (
                            <Typography>
                                {Config.lang("TCG")}: {`${insertColon(data?.SBetOTFrom)} - ${insertColon(data?.SBetOTTo)}`}
                            </Typography>
                        ) : (
                            ""
                        )}

                        {(data?.SAftOTFrom !== "" || data?.SAftOTTo !== "") ? (
                            <Typography>
                                {Config.lang("TCS")}: {`${insertColon(data?.SAftOTFrom)} - ${insertColon(data?.SAftOTTo)}`}
                            </Typography>
                        ) : (
                            ""
                        )}
                    </div>
                </Col>
                <Col xs={12}>
                    <div className={"display_row"}>
                        <Typography className={"mgr3x"} type={"h3"} uppercase>
                            {Config.lang("Gio_vao_raU")}:
                        </Typography>
                        <div className={"display_row flex-wrap"} style={{ flex: 1 }}>
                            {arrChips.map(chip => {
                                return (
                                    <Chip
                                        key={chip.indexTime}
                                        className={"mgb2x pdl3x pdr3x mgr1x"}
                                        startIcon={chip.icon}
                                        label={`${insertColon(data[`TimeOn${chip.indexTime}`])} - ${insertColon(
                                            data[`TimeOff${chip.indexTime}`]
                                        )}`}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </Col>
                <Col xs={12} style={{width: "100%", overflowX: "auto"}}>
                    <Row style={{minWidth: 600}} columnSpacing={0} rowSpacing={0}>
                        <Col xs={1} sm={2}>
                            <Row columnSpacing={0} rowSpacing={0}>
                                <Col xs={12} className={classes.borderBottomDark} style={{height: 37}} />
                                {(data?.PreOTFrom !== "" || data?.PreOTTo !== "") &&
                                    cellRenderText(Config.lang("TCT"), classes.borderBottomDark2)
                                }
                                {(data?.BetOTFrom !== "" || data?.BetOTTo !== "") &&
                                    cellRenderText(Config.lang("TCG"), classes.borderBottomDark2)
                                }
                                {(data?.AftOTFrom !== "" || data?.AftOTTo !== "") &&
                                    cellRenderText(Config.lang("TCS"))
                                }
                            </Row>
                        </Col>
                        <Col xs={1} sm={2}>
                            <Row columnSpacing={0} rowSpacing={0}>
                                <Col xs={12} className={classes.borderBottomDark}>
                                    <Typography center type={"h3"} className={"pdt2x pdb2x"} color={"info"} uppercase>
                                        {Config.lang("Thuc_te")}
                                    </Typography>
                                </Col>
                                {["PreOT", "BetOT", "AftOT"].map((key) => {
                                    if(data?.[`${key}From`] !== "" || data?.[`${key}To`] !== "") {
                                        const classBorder = key !== "AftOT" ? classes.borderBottomDark2 : "";
                                        return cellRenderInfo(data?.[`Ori${key}Hours`], classBorder, key)
                                    } else {
                                        return ""
                                    }

                                })}
                            </Row>
                        </Col>
                        <Col xs={5} sm={4}>
                            <Row columnSpacing={0} rowSpacing={0}>
                                <Col xs={12} className={classes.borderBottomDark}>
                                    <Typography center type={"h3"} className={"pdt2x pdb2x"} color={"success"} uppercase>
                                        {Config.lang("Ke_hoach")}
                                    </Typography>
                                </Col>
                                {(data?.PreOTFrom !== "" || data?.PreOTTo !== "") && (
                                    <Col xs={12} className={classes.borderBottomDark2}>
                                        {cellRenderSuccess({
                                            OTFrom: data?.PreOTFrom,
                                            OTTo: data?.PreOTTo,
                                            OTHours: data?.PreOTHours ?? "",
                                        })}
                                    </Col>
                                )}
                                {(data?.BetOTFrom !== "" || data?.BetOTTo !== "") && (
                                    <Col xs={12} className={classes.borderBottomDark2}>
                                        {cellRenderSuccess({
                                            OTFrom: data?.BetOTFrom,
                                            OTTo: data?.BetOTTo,
                                            OTHours: data?.BetOTHours ?? "",
                                        })}
                                    </Col>
                                )}
                                {(data?.AftOTFrom !== "" || data?.AftOTTo !== "") && (
                                    <Col xs={12}>
                                        {cellRenderSuccess({
                                            OTFrom: data?.AftOTFrom,
                                            OTTo: data?.AftOTTo,
                                            OTHours: data?.AftOTHours ?? "",
                                        })}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        <Col xs={5} sm={4}>
                            <Row columnSpacing={0} rowSpacing={0}>
                                <Col xs={12} className={classes.borderBottomDark}>
                                    <Typography center type={"h3"} className={"pdt2x pdb2x"} color={"warning"} uppercase>
                                        {Config.lang("Dieu_chinh")}
                                    </Typography>
                                </Col>
                                {(data?.PreOTFrom !== "" || data?.PreOTTo !== "") && (
                                    <Col xs={12} className={classes.borderBottomDark2}>
                                        {cellRenderWarning({
                                            OTFrom: data?.CPreOTFrom,
                                            OTTo: data?.CPreOTTo,
                                            OTHours: data?.CPreOTHours ?? "",
                                        })}
                                    </Col>
                                )}
                                {(data?.BetOTFrom !== "" || data?.BetOTTo !== "") && (
                                    <Col xs={12} className={classes.borderBottomDark2}>
                                        {cellRenderWarning({
                                            OTFrom: data?.CBetOTFrom,
                                            OTTo: data?.CBetOTTo,
                                            OTHours: data?.CBetOTHours ?? "",
                                        })}
                                    </Col>
                                )}
                                {(data?.AftOTFrom !== "" || data?.AftOTTo !== "") && (
                                    <Col xs={12}>
                                        {cellRenderWarning({
                                            OTFrom: data?.CAftOTFrom,
                                            OTTo: data?.CAftOTTo,
                                            OTHours: data?.CAftOTHours ?? "",
                                        })}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <TextInput hoverTooltip readOnly value={data?.Reason} label={Config.lang("Ly_do")} />
                </Col>
                <Col xs={12}>
                    <TextInput hoverTooltip readOnly value={data?.Note} label={Config.lang("Ghi_chu")} />
                </Col>
            </Row>
        );
    };

    return (
        <>
            {openW29F2309 ? <W29F2309 open={openW29F2309} onClose={onOpenW29F2039} /> : ""}
            <ActionToolbar title={Config.lang("Duyet_thay_doi_ke_hoach_tang_ca")}>
                <Button
                    label={Config.lang("Duyet_hang_loat")}
                    disabled={iPermission <= 1 || loading}
                    color={"primary"}
                    viewType={"filled"}
                    startIcon={"ApprovalDouble"}
                    onClick={() => onOpenW29F2039()}
                />
            </ActionToolbar>
            <Approvals
                ref={approvalRef}
                parentProps={props}
                FormID={"W29F2308"}
                dataForm={dataForm}
                dataFilter={dataFilter}
                singleClick
                loading={loading}
                formInfoRender={renderFormInfo}
                filterRender={renderFilter}
                onSelectionChanged={onSelectionChanged}
            />
        </>
    );
};

export default W29F2308;
